<div class="relative flex flex-col md:w-128 -m-6">
  <!-- Content -->
  <div class="flex flex-col sm:flex-row items-center sm:items-start p-8 pb-6 sm:pb-8">
    <div class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 space-y-1 text-center sm:text-left">
      <!-- Message -->
      <div class="text-xl leading-6 font-medium" [innerHTML]="message"></div>
    </div>
  </div>

  <!-- Actions -->
  <div
    class="
      flex
      items-center
      justify-center
      sm:justify-end
      px-6
      py-4
      space-x-3
      bg-gray-50
      dark:bg-black dark:bg-opacity-10
    "
  >
    <!-- Confirm -->
    <button mat-flat-button [color]="'warn'" [matDialogClose]="'yes'">Yes</button>

    <!-- Cancel -->
    <button mat-stroked-button [matDialogClose]="''">No</button>
  </div>
</div>
