import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RestaurantRatingsCategoryService {
  // Private
  private _categories: BehaviorSubject<any[] | null> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  get categories$(): Observable<any[]> {
    return this._categories.asObservable();
  }

  getAllCategories(): Observable<any[]> {
    return this._httpClient.get<any>(environment.apiURL + 'restaurant/ratingsCategories/adminGetAll').pipe(
      tap((categories) => {
        this._categories.next(categories.categories);
      }),
      map((categories) => {
        return categories.categories;
      })
    );
  }

  getAllCategoriesByRestaurant(restaurantId: string): Observable<any[]> {
    return this._httpClient
      .get<any>(environment.apiURL + 'restaurant/ratingsCategories', { params: { restaurantId } })
      .pipe(
        tap((categories) => {
          this._categories.next(categories.categories);
        }),
        map((categories) => categories.categories)
      );
  }

  create(data: any): Observable<{ category: any; message: string }> {
    return this.categories$.pipe(
      take(1),
      switchMap((allrecords) =>
        this._httpClient
          .post<{ category: any; message: string }>(environment.apiURL + 'restaurant/ratingsCategories/add', data)
          .pipe(
            map((newRecord) => {
              // Update the products with the new product
              this._categories.next([newRecord.category, ...allrecords]);

              // Return the new product
              return newRecord;
            })
          )
      )
    );
  }

  update(data: any, id: string): Observable<{ category: any; message: string }> {
    return this.categories$.pipe(
      take(1),
      switchMap((allrecords) =>
        this._httpClient
          .put<{ category: any; message: string }>(environment.apiURL + 'restaurant/ratingsCategories/edit/' + id, data)
          .pipe(
            map((updateData) => {
              const index = allrecords.findIndex((item) => item._id === id);

              allrecords[index] = updateData.category;

              this._categories.next(allrecords);

              return updateData;
            })
          )
      )
    );
  }

  enableDisable(id: string): Observable<{ isDisabled: boolean; message: string }> {
    return this.categories$.pipe(
      take(1),
      switchMap((allrecords) =>
        this._httpClient
          .get<{ isDisabled: boolean; message: string }>(
            environment.apiURL + 'restaurant/ratingsCategories/enableDisable/' + id
          )
          .pipe(
            map((updateData) => {
              const index = allrecords.findIndex((item) => item._id === id);

              allrecords[index].isDisabled = updateData.isDisabled;

              this._categories.next(allrecords);

              return updateData;
            })
          )
      )
    );
  }

  public delete(id: string, forceDelete: boolean = false) {
    return this.categories$.pipe(
      take(1),
      switchMap((categories) =>
        this._httpClient
          .delete<any>(environment.apiURL + 'restaurant/ratingsCategories/del/' + id, {
            params: { forceDelete },
            headers: { skip: 'true' },
          })
          .pipe(
            map((response) => {
              const index = categories.findIndex((item) => item._id === id);

              categories.splice(index, 1);

              this._categories.next(categories);
              return response;
            })
          )
      )
    );
  }
}
