import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { UserService } from 'app/core/services';
import { FuseDrawerService } from '@fuse/components/drawer';
import { map, takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Post } from 'app/core/models';
import { tap } from 'lodash';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddUserGoodieDialogComponent } from '../addGoodie-dialog/addUserGoodie-dialog.component';

@Component({
  selector: 'userdetails-drawer',
  templateUrl: './userDetails.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersDetailsComponent implements OnInit, OnDestroy {
  editMode: boolean = false;
  userDetails: any;
  isLoading: boolean = false;
  savedPosts: Post[] = [];

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    public _userService: UserService,
    private _fuseDrawerService: FuseDrawerService,
    private matSnackbar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.isLoading = true;
    this._changeDetectorRef.markForCheck();
    forkJoin([
      this._userService.getUserDetails(this._userService.userDetailsDrawer.userId),
      this._userService.getSavedPostByUserId(this._userService.userDetailsDrawer.userId),
    ])
      .pipe(
        takeUntil(this._unsubscribeAll),
        map(([userDetails, postsReponse]) => ({
          userDetails,
          posts: postsReponse.posts,
        }))
      )
      .subscribe(
        (responses) => {
          this.isLoading = false;
          this.userDetails = responses.userDetails;
          this.savedPosts = responses.posts;
          this._changeDetectorRef.detectChanges();
        },
        (error) => {
          this.isLoading = false;
          this.closeDrawer();
          this.handleError(error);
        }
      );
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this._userService.userDetailsDrawer.userId = '';
    this._changeDetectorRef.detectChanges();
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Close the drawer
   */
  closeDrawer() {
    const drawer = this._fuseDrawerService.getComponent('userDetailsDrawer');
    drawer.close();
  }

  getInitials(name: string) {
    let names = name.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }

  addUserGoodie() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      userId: this.userDetails.user._id,
      username: this.userDetails.user.username,
      type: 'Add',
    };

    const dialogfRef = this.dialog.open(AddUserGoodieDialogComponent, dialogConfig);

    dialogfRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((val) => {
        if (val) {
          this.loadData();
        }
      });
  }

  private handleError(msg: string) {
    this.isLoading = false;
    this.matSnackbar.open(msg, 'OK', {
      duration: 5000,
      panelClass: ['bg-warn'],
    });
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
