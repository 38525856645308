import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { FuseAnimations } from '@fuse/animations';
import { Goodie } from 'app/core/models';
import { FoodaholixService, GoodiesService } from 'app/core/services';
import { merge, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AddUserGoodieDialogComponent } from '../addGoodie-dialog/addUserGoodie-dialog.component';
import { EditPostDialogComponent } from '../editPost-dialog/editPost-dialog.component';

@Component({
  selector: 'userGoodieLog-dialog',
  templateUrl: './userGoodieLog-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: FuseAnimations,
})
export class UserGoodieLogDialogComponent implements OnInit {
  data: { userId: string; username: string };
  isLoading: boolean = false;
  showSpinner: boolean = false;
  displayedColumns: string[] = [
    'earnedFor',
    'coinsEarned',
    'earnedForId',
    'reason',
    'createdAt',
    'updatedAt',
    'actions',
  ];
  pageSize = 25;
  goodiesCount: number = 0;
  @ViewChild(MatPaginator) private _paginator: MatPaginator;
  @ViewChild(MatSort) private _sort: MatSort;
  goodies$: Observable<Goodie[]>;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private dialogRef: MatDialogRef<UserGoodieLogDialogComponent>,
    private _goodiesService: GoodiesService,
    private _foodaholixService: FoodaholixService,
    private matSnackbar: MatSnackBar,
    private dialog: MatDialog,
    private _changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.data = data;
  }

  ngOnInit() {
    this.goodies$ = this._goodiesService.userGoodieLog$;
    // Get the users
    this._goodiesService.paginatedApiResponse$.pipe(takeUntil(this._unsubscribeAll)).subscribe((resp) => {
      // Update the counts
      this.goodiesCount = resp?.totalItems;

      // Mark for check
      this._changeDetectorRef.detectChanges();
    });

    this.showSpinner = true;
    this._goodiesService.userGoodiesLog(this.data.userId, 1, 20, 'updatedAt', 'desc').subscribe(
      (d) => {
        this.showSpinner = false;
        this.subscribeToSortAndPageChanges();
      },
      (err) => {
        this.showSpinner = false;
        this.handleError(err);
      }
    );
  }

  ngAfterViewInit() {}

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  subscribeToSortAndPageChanges() {
    // If the user changes the sort order...
    this._sort.sortChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      // Reset back to the first page
      this._paginator.pageIndex = 0;
    });

    // Get users if sort or page changes
    merge(this._sort.sortChange, this._paginator.page)
      .pipe(
        switchMap(() => {
          this.isLoading = true;
          return this.loadGoodies();
        }),
        map(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        () => ({}),
        (error) => this.handleError(error)
      );
  }

  loadGoodies() {
    return this._goodiesService.userGoodiesLog(
      this.data.userId,
      this._paginator ? this._paginator.pageIndex + 1 : 1,
      this._paginator ? this._paginator.pageSize : this.pageSize,
      this._sort ? this._sort.active : 'createdAt',
      this._sort ? this._sort.direction : 'desc'
    );
  }

  showPostDetails(postId: string): void {
    if (postId) {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.autoFocus = false;
      dialogConfig.width = '100vh';

      dialogConfig.data = {
        postId,
      };

      const dialogRef = this.dialog.open(EditPostDialogComponent, dialogConfig);

      dialogRef.afterClosed().subscribe((val) => {
        if (val) {
          this._changeDetectorRef.detectChanges();
        }
      });
    }
  }

  onEdit(data: Goodie) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      ...this.data,
      type: 'Edit',
      editDoc: data,
    };

    this.dialog.open(AddUserGoodieDialogComponent, dialogConfig);
  }

  deleteItem(data: Goodie) {
    this._foodaholixService
      .confirmationPopup('Are you sure you want to delete this item?')
      .pipe(
        filter((value) => !!value),
        tap(() => (this.isLoading = true)),
        switchMap(() => this._goodiesService.delete(data._id)),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(
        (response) => {
          this.isLoading = false;
          this.successMessage(response.message);
        },
        (err) => {
          this.isLoading = false;
          this.handleError(err);
        }
      );
  }

  addUserGoodie() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      ...this.data,
      type: 'Add',
    };

    const dialogfRef = this.dialog.open(AddUserGoodieDialogComponent, dialogConfig);

    dialogfRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((val) => {
        if (val) {
          this.loadGoodies().subscribe(
            () => ({}),
            (error) => this.handleError(error)
          );
        }
      });
  }

  private handleError(msg: string) {
    this.matSnackbar.open(msg, 'OK', {
      duration: 5000,
      panelClass: ['bg-warn'],
    });
  }

  private successMessage(msg: string) {
    this.matSnackbar.open(msg, '', {
      duration: 3000,
      panelClass: ['bg-primary'],
    });
  }

  close() {
    this.dialogRef.close(false);
  }
}
