<div class="relative flex flex-col -m-6" [ngClass]="{ 'md:w-128': downloadImage }">
  <!-- Dismiss button -->
  <div class="absolute top-0 right-0 pt-4 pr-4">
    <button mat-icon-button [matDialogClose]="undefined">
      <mat-icon class="text-secondary" [svgIcon]="'heroicons_outline:x'"></mat-icon>
    </button>
  </div>

  <!-- Content -->
  <div class="flex flex-col gap-4 items-center p-8">
    <div class="text-xl leading-6 mt-8" *ngIf="title" [innerHTML]="title"></div>
    <ng-container *ngIf="downloadProgress$ | async as downloadProgress">
      <mat-progress-spinner *ngIf="downloadProgress?.state !== 'DONE'" mode="indeterminate"> </mat-progress-spinner>
    </ng-container>

    <ng-container *ngIf="downloadImage; else normalImage">
      <img class="rounded-2xl" [src]="imageUrl | secure | async" alt="Image" />
    </ng-container>
    <ng-template #normalImage>
      <img class="mt-5" [src]="imageUrl" alt="Image" />
    </ng-template>
  </div>

  <!-- Actions -->
  <ng-container>
    <div
      class="
        flex
        items-center
        justify-center
        sm:justify-end
        px-6
        py-4
        space-x-3
        bg-gray-50
        dark:bg-black dark:bg-opacity-10
      "
    >
      <!-- Close -->
      <ng-container>
        <button mat-stroked-button [matDialogClose]="'closed'">Close</button>
      </ng-container>
    </div>
  </ng-container>
</div>
