import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { OpeningHour, Restaurant } from '../models';

@Injectable({
  providedIn: 'root',
})
export class RestaurantService {
  public allRestaurants: BehaviorSubject<Restaurant[] | null> = new BehaviorSubject(null);
  private paginatedApiResponse: BehaviorSubject<{ data: Restaurant[]; totalPages: number; totalItems: number } | null> =
    new BehaviorSubject(null);
  private restaurantOpeningHours: BehaviorSubject<OpeningHour[] | null> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get allRestaurants$(): Observable<Restaurant[]> {
    return this.allRestaurants.asObservable();
  }

  get paginatedApiResponse$(): Observable<{ data: Restaurant[]; totalPages: number; totalItems: number }> {
    return this.paginatedApiResponse.asObservable();
  }

  get restaurantOpeningHours$(): Observable<OpeningHour[]> {
    return this.restaurantOpeningHours.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getRestaurants(
    pageNo: number,
    size: number,
    sortBy: string,
    sortDirection: string,
    name: string,
    ids = '',
    cuisines = '',
    city = '',
    nonEnglish = false
  ): Observable<{ data: Restaurant[]; totalPages: number; totalItems: number }> {
    return this._httpClient
      .get<{ data: Restaurant[]; totalPages: number; totalItems: number }>(
        environment.apiURL + 'restaurant/getAllAgg',
        {
          params: {
            pageNo,
            size,
            sortBy,
            sortDirection,
            name,
            ids,
            cuisines,
            city,
            nonEnglish,
          },
        }
      )
      .pipe(
        tap((response) => {
          this.allRestaurants.next(response.data);
          this.paginatedApiResponse.next(response);
        })
      );
  }

  enableDisableRestaurant(id: string): Observable<any> {
    return this.allRestaurants$.pipe(
      take(1),
      switchMap((allRestaurants) =>
        this._httpClient.post(environment.apiURL + 'restaurant/enableDisable', { id }).pipe(
          map((updateData: any) => {
            const index = allRestaurants.findIndex((item) => item._id === id);

            allRestaurants[index].isDisabled = updateData.isDisabled;

            this.allRestaurants.next(allRestaurants);

            return updateData;
          })
        )
      )
    );
  }

  /**
   * Create restaurant
   */
  createRestaurant(data: Restaurant): Observable<{ message: string; restaurant: Restaurant }> {
    return this.allRestaurants$.pipe(
      take(1),
      switchMap((restaurants) =>
        this._httpClient
          .post<{ message: string; restaurant: Restaurant }>(environment.apiURL + 'restaurant/add', data)
          .pipe(
            map((newRestaurant) => {
              // this.allRestaurants.next([newRestaurant.restaurant, ...restaurants]);

              return newRestaurant;
            })
          )
      )
    );
  }

  updateRestaurant(
    id: string,
    data: Restaurant,
    forceUpdate: boolean = false
  ): Observable<{ message: string; restaurant: Restaurant }> {
    return this.allRestaurants$.pipe(
      take(1),
      switchMap((allRestaurants) =>
        this._httpClient
          .post<{ message: string; restaurant: Restaurant }>(environment.apiURL + 'restaurant/edit/' + id, data, {
            params: { forceUpdate },
            headers: { skip: 'true' },
          })
          .pipe(
            map((updateData) => {
              // Find the index of the updated restaurant
              const index = allRestaurants.findIndex((item) => item._id === id);

              // Update the restaurant
              allRestaurants[index] = updateData.restaurant;

              // Update the users
              this.allRestaurants.next(allRestaurants);

              // Return the updated user
              return updateData;
            })
          )
      )
    );
  }

  public delete(id: string, forceDelete: boolean = false) {
    return this.allRestaurants$.pipe(
      take(1),
      switchMap((restaurants) =>
        this._httpClient
          .delete<any>(environment.apiURL + 'restaurant/del/' + id, {
            params: { forceDelete },
            headers: { skip: 'true' },
          })
          .pipe(
            map((response) => {
              const index = restaurants.findIndex((item) => item._id === id);

              restaurants.splice(index, 1);

              this.allRestaurants.next(restaurants);
              return response;
            })
          )
      )
    );
  }

  enableDisable(id: string): Observable<{ isDisabled: boolean; message: string }> {
    return this.allRestaurants$.pipe(
      take(1),
      switchMap((allrecords) =>
        this._httpClient
          .get<{ isDisabled: boolean; message: string }>(environment.apiURL + 'restaurant/enableDisable/' + id)
          .pipe(
            map((updateData) => {
              const index = allrecords.findIndex((item) => item._id === id);

              allrecords[index].isDisabled = updateData.isDisabled;

              this.allRestaurants.next(allrecords);

              return updateData;
            })
          )
      )
    );
  }

  updateCuisine(
    restaurantID: string,
    data: { cuisine: string }
  ): Observable<{ message: string; restaurant: Partial<Restaurant> }> {
    return this.allRestaurants$.pipe(
      take(1),
      switchMap((allrecords) =>
        this._httpClient
          .post<{ message: string; restaurant: Partial<Restaurant> }>(
            environment.apiURL + 'restaurant/updateCuisine/' + restaurantID,
            data
          )
          .pipe(
            map((updateData) => {
              const index = allrecords.findIndex((item) => item._id === restaurantID);

              allrecords[index].cuisine = updateData.restaurant.cuisine;

              this.allRestaurants.next(allrecords);

              return updateData;
            })
          )
      )
    );
  }

  public deleteMultiple(restaurantIDs: string, forceDelete: boolean = false) {
    return this.allRestaurants$.pipe(
      take(1),
      switchMap((restaurants) =>
        this._httpClient
          .post<any>(
            environment.apiURL + 'restaurant/multiDel',
            { restaurantIDs },
            {
              params: { forceDelete },
              headers: { skip: 'true' },
            }
          )
          .pipe(
            map((response) => {
              restaurants = restaurants.filter((restaurant) => !restaurantIDs.split(',').includes(restaurant._id));

              this.allRestaurants.next(restaurants);
              return response;
            })
          )
      )
    );
  }

  public uploadRestaurants(data: any) {
    return this._httpClient.post<any>(environment.apiURL + 'bulkinsert/restaurants', data);
  }

  regenQr(restaurantId = ''): Observable<{ message: string }> {
    let url = environment.apiURL + 'restaurant/regenQr';
    if (restaurantId) {
      url += '/' + restaurantId;
    }
    return this.allRestaurants$.pipe(
      take(1),
      switchMap((allRestaurants) =>
        this._httpClient.patch(url, null).pipe(
          map((updateData: any) => {
            if (restaurantId) {
              const index = allRestaurants.findIndex((item) => item._id === restaurantId);

              allRestaurants[index].reGenQr = true;

              this.allRestaurants.next(allRestaurants);
            }
            return updateData;
          })
        )
      )
    );
  }

  getRestaurantDetails(id: string): Observable<{ restaurant: Restaurant; message: string }> {
    return this._httpClient.get<{ restaurant: Restaurant; message: string }>(
      environment.apiURL + 'restaurant/details/' + id
    );
  }

  public setOpeningHour(openingHours: OpeningHour[]) {
    this.restaurantOpeningHours.next(openingHours);
  }

  public getAllUserRatings(restaurantId: string) {
    return this._httpClient.get<any[]>(environment.apiURL + 'restaurant/ratingsAll', {
      params: { restaurantId },
    });
  }

  public getOverallRatings(restaurantId: string) {
    return this._httpClient.get(environment.apiURL + 'restaurant/ratings', {
      params: { restaurantId },
    });
  }

  download(
    pageNo: number,
    size: number,
    sortBy: string,
    sortDirection: string,
    name: string,
    ids = '',
    cuisines = '',
    city = '',
    nonEnglish = false
  ): Observable<any> {
    return this._httpClient
      .post(
        environment.apiURL + 'restaurant/download',
        {},
        {
          params: {
            pageNo,
            size,
            sortBy,
            sortDirection,
            city,
            cuisines,
            ids,
            nonEnglish,
            isDisabled: false,
            name,
          },
          responseType: 'blob',
          observe: 'response',
        }
      )
      .pipe(
        map((res) => {
          // Extract content disposition header
          const contentDisposition = res.headers.get('Content-Disposition');

          // Extract the file name
          const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();

          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename,
          };
          return data;
        })
      );
  }
}
