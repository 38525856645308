import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Goodie } from '../models';

@Injectable({
  providedIn: 'root',
})
export class GoodiesService {
  private _userGoodieLog: BehaviorSubject<Goodie[] | null> = new BehaviorSubject(null);
  private paginatedApiResponse: BehaviorSubject<{ data: Goodie[]; totalPages: number; totalItems: number } | null> =
    new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get userGoodieLog$(): Observable<Goodie[]> {
    return this._userGoodieLog.asObservable();
  }

  get paginatedApiResponse$(): Observable<{ data: Goodie[]; totalPages: number; totalItems: number }> {
    return this.paginatedApiResponse.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  userGoodiesLog(
    userID: string,
    pageNo: number,
    size: number,
    sortBy: string,
    sortDirection: string
  ): Observable<{ data: Goodie[]; totalPages: number; totalItems: number }> {
    return this._httpClient
      .get<{ data: Goodie[]; totalPages: number; totalItems: number }>(environment.apiURL + 'goodies/log/' + userID, {
        params: {
          pageNo,
          size,
          sortBy,
          sortDirection,
        },
      })
      .pipe(
        tap((response) => {
          this._userGoodieLog.next(response.data);
          this.paginatedApiResponse.next(response);
        })
      );
  }

  adminUpdateGoodie(data: any): Observable<{ message: string; goodie: Goodie }> {
    return this.userGoodieLog$.pipe(
      take(1),
      switchMap((allRecords) =>
        this._httpClient.patch<{ message: string; goodie: Goodie }>(environment.apiURL + 'goodies/update', data).pipe(
          map((updateData) => {
            const index = allRecords.findIndex((item) => item._id === data.goodieId);

            allRecords[index] = updateData.goodie;

            this._userGoodieLog.next(allRecords);

            return updateData;
          })
        )
      )
    );
  }

  adminAddGoodie(data: any): Observable<{ message: string; goodie: Goodie }> {
    return this._httpClient.post<{ message: string; goodie: Goodie }>(environment.apiURL + 'goodies/add', data);
  }

  public delete(id: string) {
    return this.userGoodieLog$.pipe(
      take(1),
      switchMap((allrecords) =>
        this._httpClient.delete<any>(environment.apiURL + 'goodies/delete/' + id).pipe(
          map((response) => {
            // Find the index of the deleted item
            const index = allrecords.findIndex((item) => item._id === id);

            // Delete the item
            allrecords.splice(index, 1);

            // Update the items
            this._userGoodieLog.next(allrecords);
            return response;
          })
        )
      )
    );
  }
}
