import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Help } from '../models';

@Injectable({
  providedIn: 'root',
})
export class HelpService {
  // Private
  private _helps: BehaviorSubject<Help[] | null> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get helps$(): Observable<Help[]> {
    return this._helps.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getHelps(): Observable<Help[]> {
    return this._httpClient.get<any>(environment.apiURL + 'help/all').pipe(
      tap((response) => {
        this._helps.next(response.helps);
      }),
      map((response) => {
        return response.helps;
      })
    );
  }
}
