<div class="flex h-screen" *ngIf="isLoading">
  <div class="m-auto">
    <mat-progress-spinner style="margin: 0 auto" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>

<!-- User -->
<div class="flex flex-col flex-auto items-center p-6 pt-0 sm:p-12 sm:pt-0" *ngIf="userDetails && !isLoading">
  <div class="w-full max-w-3xl">
    <!-- Close button -->
    <div class="flex items-start justify-start ml-auto">
      <button mat-icon-button [matTooltip]="'Close'" (click)="closeDrawer()">
        <mat-icon class="text-accent" [svgIcon]="'heroicons_outline:x'"></mat-icon>
      </button>
    </div>

    <!-- Avatar and username -->
    <div class="flex flex-auto items-end mt-5">
      <!-- Avatar -->
      <div class="flex items-center justify-center w-32 h-32 rounded-full overflow-hidden ring-4 ring-bg-card">
        <img
          class="object-cover w-full h-full"
          *ngIf="userDetails.user.profilePic"
          [src]="userDetails.user.profilePic"
          alt="profile pic"
          (error)="userDetails.user.profilePic = ''"
        />
        <div
          class="
            flex
            items-center
            justify-center
            w-full
            h-full
            rounded
            overflow-hidden
            uppercase
            text-8xl
            font-bold
            leading-none
            bg-gray-200
            text-gray-600
            dark:bg-gray-700 dark:text-gray-200
          "
          *ngIf="!userDetails.user.profilePic"
        >
          {{ getInitials(userDetails.user.username) }}
        </div>
      </div>
      <!-- username and email -->
      <div class="flex flex-col pl-5 mb-9">
        <div class="text-4xl font-bold break-words">{{ userDetails.user.username }}</div>
        <div class="flex items-center">
          <div class="leading-6 text-secondary text-xl">{{ userDetails.user.email }}</div>
        </div>
      </div>
    </div>

    <!-- foodPreferences -->
    <ng-container *ngIf="userDetails.user.foodPreferences.length">
      <div class="flex flex-wrap items-center mt-5">
        <!-- foodPreference -->
        <ng-container *ngFor="let foodPreference of userDetails.user.foodPreferences; trackBy: trackByFn">
          <div
            class="
              flex
              items-center
              justify-center
              py-1
              px-3
              mr-3
              mb-3
              rounded-full
              leading-normal
              text-gray-500
              bg-gray-100
              dark:text-gray-300 dark:bg-gray-700
            "
          >
            <span class="text-lg font-medium whitespace-nowrap">{{ foodPreference.name }}</span>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <div class="flex flex-col mt-4 pt-6 border-t space-y-8">
      <!-- ID -->
      <ng-container>
        <div class="flex sm:items-center">
          <mat-icon [svgIcon]="'heroicons_outline:identification'"></mat-icon>
          <span class="ml-2 text-lg font-medium whitespace-nowrap">ID:</span>
          <div class="ml-2 leading-6">{{ userDetails.user._id }}</div>
        </div>
      </ng-container>

      <!-- Address -->
      <ng-container>
        <div class="flex sm:items-center">
          <mat-icon [svgIcon]="'heroicons_outline:location-marker'"></mat-icon>
          <div class="ml-2 leading-6">
            {{
              userDetails.user.city?.name + ', ' + userDetails.user.state?.name + ', ' + userDetails.user.country?.name
            }}
          </div>
        </div>
      </ng-container>

      <!-- Appreciations -->
      <ng-container>
        <div class="flex sm:items-center">
          <mat-icon [svgIcon]="'heroicons_outline:thumb-up'"></mat-icon>
          <span class="ml-2 text-lg font-medium whitespace-nowrap">Total Appreciations:</span>
          <div class="ml-2 leading-6">{{ userDetails.totalAppreciation }}</div>
        </div>
      </ng-container>

      <!-- Goodies -->
      <ng-container>
        <div class="flex">
          <mat-icon [svgIcon]="'heroicons_outline:gift'"></mat-icon>
          <div class="ml-2 min-w-0 space-y-1">
            <div class="flex items-start">
              <span class="text-lg font-medium whitespace-nowrap">Goodies:</span>
              <!-- Add user goodie button -->
              <button
                class="ml-4"
                style="height: 27px !important; min-height: 27px !important"
                mat-flat-button
                [color]="'primary'"
                (click)="addUserGoodie()"
              >
                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1">Add</span>
              </button>
            </div>

            <div class="flex items-center leading-6">
              <div class="font-mono text-base font-medium whitespace-nowrap">Total Earned</div>
              <div class="text-md truncate text-secondary">
                <span class="mx-2">&bull;</span>
                <span class="font-medium">{{ userDetails.goodiesDetails.totalEarned }}</span>
              </div>
            </div>

            <div class="flex items-center leading-6">
              <div class="font-mono text-base font-medium whitespace-nowrap">Total Earned by Refer</div>
              <div class="text-md truncate text-secondary">
                <span class="mx-2">&bull;</span>
                <span class="font-medium">{{ userDetails.goodiesDetails.totalEarnedByRefer }}</span>
              </div>
            </div>

            <div class="flex items-center leading-6">
              <div class="font-mono text-base font-medium whitespace-nowrap">Total Earned today</div>
              <div class="text-md truncate text-secondary">
                <span class="mx-2">&bull;</span>
                <span class="font-medium">{{ userDetails.goodiesDetails.currentDayGoodie }}</span>
              </div>
            </div>

            <div class="flex items-center leading-6">
              <div class="font-mono text-base font-medium whitespace-nowrap">Total Earned this week</div>
              <div class="text-md truncate text-secondary">
                <span class="mx-2">&bull;</span>
                <span class="font-medium">{{ userDetails.goodiesDetails.currentWeekGoodie }}</span>
              </div>
            </div>

            <div class="flex items-center leading-6">
              <div class="font-mono text-base font-medium whitespace-nowrap">Daily left</div>
              <div class="text-md truncate text-secondary">
                <span class="mx-2">&bull;</span>
                <span class="font-medium">{{ userDetails.goodiesDetails.dailyLeft }}</span>
              </div>
            </div>

            <div class="flex items-center leading-6">
              <div class="font-mono text-base font-medium whitespace-nowrap">Weekly left</div>
              <div class="text-md truncate text-secondary">
                <span class="mx-2">&bull;</span>
                <span class="font-medium">{{ userDetails.goodiesDetails.weeklyLeft }}</span>
              </div>
            </div>

            <div class="flex items-center leading-6">
              <div class="font-mono text-base font-medium whitespace-nowrap">Nearest milestone</div>
              <div class="text-md truncate text-secondary">
                <span class="mx-2">&bull;</span>
                <span class="font-medium">{{ userDetails.goodiesDetails.milestoneTxt }}</span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- referralCode -->
      <ng-container>
        <div class="flex sm:items-center">
          <mat-icon [svgIcon]="'heroicons_outline:share'"></mat-icon>
          <span class="ml-2 text-lg font-medium whitespace-nowrap">Referral Code:</span>
          <div class="ml-2 leading-6">{{ userDetails.user.referralCode }}</div>
        </div>
      </ng-container>

      <!-- Referrer -->
      <ng-container>
        <div class="flex sm:items-center">
          <mat-icon [svgIcon]="'heroicons_outline:user'"></mat-icon>
          <span class="ml-2 text-lg font-medium whitespace-nowrap">Referred By:</span>
          <div class="ml-2 leading-6">{{ userDetails.user.referrer?.username }}</div>
        </div>
      </ng-container>
    </div>

    <hr class="my-8" />

    <!-- Saved post -->
    <div class="text-lg font-semibold text-secondary">Saved Posts</div>
    <div class="flex flex-auto mt-5" *ngFor="let post of savedPosts; trackBy: trackByFn">
      <!-- Post image -->
      <div class="flex items-center justify-center w-32 h-32 rounded-lg overflow-hidden ring-4 ring-bg-card">
        <img class="object-cover w-full h-full" *ngIf="post.imageUrl" [src]="post.imageUrl" alt="dish image" />
        <!-- <div class="flex items-center justify-center w-full h-full rounded overflow-hidden uppercase text-8xl font-bold leading-none bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200"
                    *ngIf="!post.imageUrl">
                    {{getInitials(userDetails.user.username)}}
                </div> -->
      </div>

      <div class="flex flex-col pl-5 mb-9">
        <div class="text-4xl font-bold break-words">{{ post.dishName }}</div>
        <div class="flex items-center">
          <div class="leading-6 text-secondary text-xl">Restaurant: {{ post.restaurant?.name }}</div>
        </div>
        <!-- Reactions -->
        <div class="mt-2 grid grid-cols-3 divide-x-2 divide-green-500">
          <!-- Love -->
          <div class="flex flex-row">
            <mat-icon [svgIcon]="'mat_outline:favorite_border'"></mat-icon>
            <span class="mx-0.5">&bull;</span>
            <span class="text-sm font-medium whitespace-nowrap">Love:</span>
            <div class="ml-1">{{ post.loveCount }}</div>
          </div>
          <!-- Like -->
          <div class="flex sm:items-center">
            <mat-icon [svgIcon]="'heroicons_outline:thumb-up'"></mat-icon>
            <span class="mx-0.5">&bull;</span>
            <span class="text-sm font-medium whitespace-nowrap">Like:</span>
            <div class="ml-1">{{ post.likeCount }}</div>
          </div>
          <!-- Regret -->
          <div class="flex sm:items-center">
            <mat-icon [svgIcon]="'heroicons_outline:emoji-sad'"></mat-icon>
            <span class="mx-0.5">&bull;</span>
            <span class="text-sm font-medium whitespace-nowrap">Regret:</span>
            <div class="ml-1">{{ post.regretCount }}</div>
          </div>
          <!-- Appreciations -->
          <div class="flex sm:items-center">
            <mat-icon [svgIcon]="'heroicons_outline:thumb-up'"></mat-icon>
            <span class="mx-0.5">&bull;</span>
            <span class="text-sm font-medium whitespace-nowrap">Appreciations:</span>
            <div class="ml-1">{{ post.appreciationCount }}</div>
          </div>
        </div>
      </div>
    </div>

    <hr class="my-8" />
  </div>
</div>
