import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FoodaholixService } from 'app/core/services';
import { Download, download } from 'app/core/services/download';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

@Pipe({
  name: 'secure',
})
export class SecurePipe implements PipeTransform {
  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private _foodaholixService: FoodaholixService
  ) {}

  transform(url: string): Observable<SafeUrl> {
    return this.http.get(url, { reportProgress: true, observe: 'events', responseType: 'blob' }).pipe(
      download(),
      tap((cv) => (this._foodaholixService.downloadProgress = cv)),
      filter((val) => !!val.content),
      map((val: Download) => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val.content)))
    );
  }
}
