import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, interval, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User } from 'app/core/models/user.model';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private userSubject: BehaviorSubject<any>;
  public user: Observable<User>;

  constructor(private router: Router, private http: HttpClient) {
    this.userSubject = new BehaviorSubject<any>(null);
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): User {
    return this.userSubject.value?.user;
  }

  public get jwtToken(): string {
    return this.userSubject.value?.token;
  }

  signIn(credentials: { email: string; password: string }) {
    return this.http.post<any>(`${environment.apiURL}auth/adminSignin`, credentials, { withCredentials: true }).pipe(
      map((user) => {
        this.userSubject.next(user);
        this.startRefreshTokenTimer();
        return user;
      })
    );
  }

  signOut() {
    this.http.post<any>(`${environment.apiURL}auth/revokeToken`, {}).subscribe();
    this.stopRefreshTokenTimer();
    this.userSubject.next(null);
  }

  refreshToken() {
    return this.http.post<any>(`${environment.apiURL}auth/refreshToken`, {}, { withCredentials: true }).pipe(
      map((user) => {
        this.userSubject.next(user);
        this.startRefreshTokenTimer();
        return user;
      })
    );
  }

  // helper methods

  private refreshTokenTimeout;

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.jwtToken.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    // This is due to javascript timeout is using a 32 bit int to store the delay so the max value allowed would be approximatively 1 month, so dont start timer when the token expiry is larger than a month
    if (timeout <= 2147483647) {
      this.refreshTokenTimeout = interval(timeout)
        .pipe(switchMap(() => this.refreshToken()))
        .subscribe();
    }
  }

  private stopRefreshTokenTimer() {
    this.refreshTokenTimeout?.unsubscribe();
  }
}
