import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationPopupComponent } from 'app/shared/confirmationPopup/confirmationPopup.component';
import csvtojsonV2 from 'csvtojson';
import { BehaviorSubject, Observable } from 'rxjs';
import { Download } from './download';

@Injectable()
export class FoodaholixService {
  private _downloadProgress: BehaviorSubject<Download | null> = new BehaviorSubject(null);

  constructor(private dialog: MatDialog) {}

  get downloadProgress$(): Observable<Download> {
    return this._downloadProgress.asObservable();
  }

  set downloadProgress(download: Download) {
    this._downloadProgress.next(download);
  }

  public confirmationPopup(message: string, title = 'Confirm Action') {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      message,
      title,
    };
    let confirmationPopup: MatDialogRef<ConfirmationPopupComponent>;
    confirmationPopup = this.dialog.open(ConfirmationPopupComponent, dialogConfig);

    return confirmationPopup.afterClosed();
  }

  /**
   * Read the given file as url
   *
   * @param file
   */
  public readAsDataURL = async (file: File | string): Promise<any> => {
    let blob: File | Blob;

    if (typeof file === 'string') {
      let res = await fetch(file);
      blob = await res.blob();
    } else blob = file;

    // Return a new promise
    return new Promise((resolve, reject) => {
      // Create a new reader
      const reader = new FileReader();

      // Resolve the promise on success
      reader.onload = () => {
        resolve(reader.result);
      };

      // Reject the promise on error
      reader.onerror = (e) => {
        reject(e);
      };

      // Read the file as the
      reader.readAsDataURL(blob);
    });
  };

  /*
   * Convert a CSV String to JSON
   */
  convertToJSON = async (file: File) => {
    // Return a new promise
    return new Promise((resolve, reject) => {
      // Create a new reader
      const reader = new FileReader();

      // Resolve the promise on success
      reader.onload = () => {
        csvtojsonV2()
          .fromString(reader.result as string)
          .then(resolve);
      };

      // Reject the promise on error
      reader.onerror = (e) => {
        reject(e);
      };

      // Read the file as text
      reader.readAsText(file);
    });
  };
}
