import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { PostReactionHashtags } from '../models';

@Injectable({
  providedIn: 'root',
})
export class PostReactionHashtagsService {
  // Private
  private _prh: BehaviorSubject<PostReactionHashtags[] | null> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get prh$(): Observable<PostReactionHashtags[]> {
    return this._prh.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  get(name = ''): Observable<PostReactionHashtags[]> {
    return this._httpClient.get<PostReactionHashtags[]>(environment.apiURL + 'postReactionHashtag/all').pipe(
      tap((data) => {
        this._prh.next(data);
      })
    );
  }

  create(data: PostReactionHashtags): Observable<{ postReactionHashtag: PostReactionHashtags; message: string }> {
    return this.prh$.pipe(
      take(1),
      switchMap((hashtags) =>
        this._httpClient
          .post<{ postReactionHashtag: PostReactionHashtags; message: string }>(
            environment.apiURL + 'postReactionHashtag/add',
            data
          )
          .pipe(
            map((newHashtags) => {
              this._prh.next([newHashtags.postReactionHashtag, ...hashtags]);

              return newHashtags;
            })
          )
      )
    );
  }

  update(
    data: PostReactionHashtags,
    id: string
  ): Observable<{ postReactionHashtag: PostReactionHashtags; message: string }> {
    return this.prh$.pipe(
      take(1),
      switchMap((hashtags) =>
        this._httpClient
          .put<{ postReactionHashtag: PostReactionHashtags; message: string }>(
            environment.apiURL + 'postReactionHashtag/edit/' + id,
            data
          )
          .pipe(
            map((updateData) => {
              const index = hashtags.findIndex((item) => item._id === id);

              hashtags[index] = updateData.postReactionHashtag;

              this._prh.next(hashtags);

              return updateData;
            })
          )
      )
    );
  }

  public delete(id: string) {
    return this.prh$.pipe(
      take(1),
      switchMap((hashtags) =>
        this._httpClient.delete<{ message: string }>(environment.apiURL + 'postReactionHashtag/del/' + id).pipe(
          map((response) => {
            const index = hashtags.findIndex((item) => item._id === id);

            hashtags.splice(index, 1);

            this._prh.next(hashtags);
            return response;
          })
        )
      )
    );
  }
}
