import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmationPopup',
  templateUrl: './confirmationPopup.component.html',
})
export class ConfirmationPopupComponent {
  message: string;
  title: string;

  constructor(public dialogRef: MatDialogRef<ConfirmationPopupComponent>, @Inject(MAT_DIALOG_DATA) { message, title }) {
    this.message = message;
    this.title = title;
  }
}
