import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-show-text-dialog',
  templateUrl: './showText-dialog.component.html',
})
export class ShowTextDialogComponent {
  message: string;
  title: string;

  constructor(public dialogRef: MatDialogRef<ShowTextDialogComponent>, @Inject(MAT_DIALOG_DATA) { message, title }) {
    this.message = message;
    this.title = title;
  }
}
