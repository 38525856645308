import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CuisineService } from 'app/core/services';

@Injectable({
  providedIn: 'root',
})
export class CuisineListResolver implements Resolve<any> {
  /**
   * Constructor
   */
  constructor(private _cuisineService: CuisineService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this._cuisineService.getCuisines();
  }
}
