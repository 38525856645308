import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { User } from 'app/core/models/user.model';
import { environment } from 'environments/environment';
import { Post } from '../models';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public allUserData: BehaviorSubject<{ data: User[]; totalPages: number; totalItems: number } | null> =
    new BehaviorSubject(null);
  private _userDetails: BehaviorSubject<{
    user: User;
    goodiesDetails: any;
    totalAppreciation: number;
    message: string;
  } | null> = new BehaviorSubject(null);
  userDetailsDrawer = { isOpen: false, userId: '' };

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get allUserData$(): Observable<{ data: User[]; totalPages: number; totalItems: number }> {
    return this.allUserData.asObservable();
  }

  get userDetails$(): Observable<{ user: User; goodiesDetails: any; totalAppreciation: number; message: string }> {
    return this._userDetails.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getUsers(
    pageNo: number,
    size: number,
    sortBy: string,
    sortDirection: string,
    email: string,
    username: string,
    city = '',
    foodContetnInfoIds = '',
    role = ''
  ): Observable<{ data: User[]; totalPages: number; totalItems: number }> {
    return this._httpClient
      .get<{ data: User[]; totalPages: number; totalItems: number }>(environment.apiURL + 'users/all', {
        params: {
          pageNo,
          size,
          sortBy,
          sortDirection,
          email,
          username,
          city,
          foodContetnInfoIds,
          role,
        },
      })
      .pipe(
        tap((response) => {
          this.allUserData.next(response);
        })
      );
  }

  enableDisableUser(id: string): Observable<any> {
    return this.allUserData$.pipe(
      take(1),
      switchMap((allUsers) =>
        this._httpClient.post(environment.apiURL + 'users/enableDisable', { id }).pipe(
          map((updateData: any) => {
            // Find the index of the updated user
            const index = allUsers.data.findIndex((item) => item._id === id);

            // Update the user
            allUsers.data[index].isDisabled = updateData.isDisabled;

            // Update the users
            this.allUserData.next(allUsers);

            // Return the updated user
            return updateData;
          })
        )
      )
    );
  }

  getUserDetails(
    id: string
  ): Observable<{ user: User; goodiesDetails: any; totalAppreciation: number; message: string }> {
    return this._httpClient.get<any>(environment.apiURL + 'users/single/' + id).pipe(
      tap((response) => {
        this._userDetails.next(response);
      })
    );
  }

  getSavedPostByUserId(userId: string): Observable<{ posts: Post[]; message: string }> {
    return this._httpClient.get<{ posts: Post[]; message: string }>(
      environment.apiURL + 'users/getSavedPosts/' + userId
    );
  }

  resetTc(userId = ''): Observable<{ message: string }> {
    let url = environment.apiURL + 'users/resetTc';
    if (userId) {
      url += '/' + userId;
    }
    return this.allUserData$.pipe(
      take(1),
      switchMap((allUsers) =>
        this._httpClient.patch(url, null).pipe(
          map((updateData: any) => {
            if (userId) {
              // Find the index of the updated user
              const index = allUsers.data.findIndex((item) => item._id === userId);

              // Update the user
              allUsers.data[index].tcAccepted = false;
              allUsers.data[index].tcAcceptDate = null;

              // Update the users
              this.allUserData.next(allUsers);
            }
            // Return the updated user
            return updateData;
          })
        )
      )
    );
  }

  sendMassInfo(data: any) {
    return this._httpClient.post<any>(environment.apiURL + 'users/sendMassInformation', data);
  }

  regenQr(userId = ''): Observable<{ message: string }> {
    let url = environment.apiURL + 'users/regenQr';
    if (userId) {
      url += '/' + userId;
    }
    return this.allUserData$.pipe(
      take(1),
      switchMap((allUsers) =>
        this._httpClient.patch(url, null).pipe(
          map((updateData: any) => {
            if (userId) {
              // Find the index of the updated user
              const index = allUsers.data.findIndex((item) => item._id === userId);

              // Update the user
              allUsers.data[index].reGenQr = true;

              // Update the users
              this.allUserData.next(allUsers);
            }
            // Return the updated user
            return updateData;
          })
        )
      )
    );
  }

  editUserProfile(data: any) {
    return this._httpClient.put<{ message: string; user: User }>(environment.apiURL + 'users/editProfileAdmin', data);
  }

  triggerNotifications(data: any) {
    return this._httpClient.post(environment.apiURL + 'notifications/sendNoti', data);
  }

  deleteUser(userId: string) {
    return this.allUserData$.pipe(
      take(1),
      switchMap((allUsers) =>
        this._httpClient
          .delete<{ message: string }>(environment.apiURL + 'users/deleteAccountByAdmin', {
            params: {
              userId,
            },
          })
          .pipe(
            map((updateData: any) => {
              if (userId) {
                // Find the index of the updated user
                const index = allUsers.data.findIndex((item) => item._id === userId);

                allUsers.data.splice(index, 1);

                // Update the users
                this.allUserData.next(allUsers);
              }
              // Return the updated user
              return updateData;
            })
          )
      )
    );
  }
}
