import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CuisineService {
  // Private
  private _cuisines: BehaviorSubject<any[] | null> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for cuisines
   */
  get cuisines$(): Observable<any[]> {
    return this._cuisines.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get cuisines
   */
  getCuisines(name = ''): Observable<any[]> {
    return this._httpClient
      .get<any>(environment.apiURL + 'cuisine/all', {
        params: { name },
      })
      .pipe(
        tap((cuisines) => {
          this._cuisines.next(cuisines.cuisines);
        }),
        map((allCuisines) => {
          return allCuisines.cuisines;
        })
      );
  }

  /**
   * Create cuisine
   */
  createCuisine(data: { name: string }): Observable<any> {
    return this.cuisines$.pipe(
      take(1),
      switchMap((cuisines) =>
        this._httpClient.post<any>(environment.apiURL + 'cuisine/add', data).pipe(
          map((newCuisine) => {
            // Update the products with the new product
            this._cuisines.next([newCuisine.cuisine, ...cuisines]);

            // Return the new product
            return newCuisine;
          })
        )
      )
    );
  }

  /**
   * update cuisine
   */
  updateCuisine(data: { name: string }, id: string): Observable<any> {
    return this.cuisines$.pipe(
      take(1),
      switchMap((cuisines) =>
        this._httpClient.put<any>(environment.apiURL + 'cuisine/edit/' + id, data).pipe(
          map((updateData) => {
            const index = cuisines.findIndex((item) => item._id === id);

            cuisines[index] = updateData.cuisine;

            this._cuisines.next(cuisines);

            return updateData;
          })
        )
      )
    );
  }

  public delete(id: string, forceDelete: boolean = false) {
    return this.cuisines$.pipe(
      take(1),
      switchMap((cuisines) =>
        this._httpClient
          .delete<any>(environment.apiURL + 'cuisine/del/' + id, {
            params: { forceDelete },
            headers: { skip: 'true' },
          })
          .pipe(
            map((response) => {
              const index = cuisines.findIndex((item) => item._id === id);

              cuisines.splice(index, 1);

              this._cuisines.next(cuisines);
              return response;
            })
          )
      )
    );
  }
}
