import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators, FormGroup, NgForm, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseAlertType } from '@fuse/components/alert';
import { FuseAnimations } from '@fuse/animations';
import {
  RestaurantService,
  CuisineService,
  FoodaholixService,
  PostsService,
  CategoryService,
  CountryCityStatesService,
  FoodContentInfoService,
  FoodAllergyService,
} from 'app/core/services';
import { forkJoin, from, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, delay, tap, filter, map, takeUntil, switchMap } from 'rxjs/operators';
import { FoodContentInfo, Post, Restaurant } from 'app/core/models';

@Component({
  selector: 'editPost-dialog',
  templateUrl: './editPost-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: FuseAnimations,
})
export class EditPostDialogComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  showImageBg: boolean = false;
  form: FormGroup;
  data: any;
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: '',
  };
  showAlert: boolean = false;
  @ViewChild('dataForm') dataForm: NgForm;
  @ViewChild('imageFileInput') private _imageFileInput: ElementRef;
  post: Post;
  categories: any[] = [];
  foodContentInfo: FoodContentInfo[] = [];
  fciSubcategory: FoodContentInfo[] = [];

  /** list of cuisines */
  cuisines: any[] = [];

  foodAllergies: any[] = [];

  image: string = '';
  initialImage: string = ''; // this is used to check if changes made in the form image

  /** indicate search operation is in progress */
  public searching = false;

  public restaurantServerSideFilteringCtrl: FormControl = new FormControl();
  public filteredServerSideRestaurants: ReplaySubject<Restaurant[]> = new ReplaySubject<Restaurant[]>(1);

  /** Subject that emits when the component has been destroyed. */
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    fb: FormBuilder,
    private dialogRef: MatDialogRef<EditPostDialogComponent>,
    private _cuisineService: CuisineService,
    private _categoryService: CategoryService,
    private _countryCityStatesService: CountryCityStatesService,
    private _foodContentInfoService: FoodContentInfoService,
    private _restaurantService: RestaurantService,
    private _postsService: PostsService,
    private _foodaholixService: FoodaholixService,
    private matSnackbar: MatSnackBar,
    private _changeDetectorRef: ChangeDetectorRef,
    private _foodAllergyService: FoodAllergyService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.data = data;
    this.form = fb.group({
      dishName: ['', Validators.required],
      restaurant: ['', Validators.required],
      parentCategory: ['', Validators.required],
      foodContentInfo: [''],
      foodContentInfoSubcategory: [[]],
      price: [null, Validators.pattern(/^\d+(\.\d{1,5})?$/)],
      foodAllergies: [[]],
    });
  }

  ngOnInit() {
    this._postsService.$post.pipe(takeUntil(this._unsubscribeAll)).subscribe((post) => {
      if (post) {
        this.post = post;
        // Fill the form
        this.form.patchValue({
          dishName: post.dishName,
          restaurant: post.restaurant?._id,
          parentCategory: post.parentCategory?._id,
          foodContentInfo: post.foodContentInfo?._id,
          foodContentInfoSubcategory:
            post.foodContentInfoSubcategory && post.foodContentInfoSubcategory.length > 0
              ? post.foodContentInfoSubcategory.map((d) => d._id)
              : [],
          price: post.price || null,
          foodAllergies:
            post.foodAllergies && post.foodAllergies.length > 0 ? post.foodAllergies.map((d) => d._id) : [],
        });
        this.filteredServerSideRestaurants.next([post.restaurant]);

        this.image = this.post.imageUrl;
        this.initialImage = this.post.imageUrl;
        if (!this.image) {
          this.showImageBg = true;
        } else {
          this.showImageBg = false;
        }

        this._changeDetectorRef.detectChanges();
      }
    });

    this.isLoading = true;
    forkJoin([
      this._postsService.getPostDetails(this.data.postId),
      this._categoryService.getAllCategories(),
      this._cuisineService.getCuisines(),
      this._foodContentInfoService.getAllFoodContentInfoApp(),
      this._foodAllergyService.getFoodAllergies(),
    ])
      .pipe(
        takeUntil(this._unsubscribeAll),
        map(([postDetails, categories, cuisines, foodContentInfos, foodAllergies]) => ({
          postDetails,
          categories,
          cuisines,
          foodContentInfos,
          foodAllergies,
        }))
      )
      .subscribe(
        (responses) => {
          this.isLoading = false;
          this.cuisines = responses.cuisines;
          this.categories = responses.categories;
          this.foodContentInfo = responses.foodContentInfos;
          this.foodAllergies = responses.foodAllergies;

          // set this value again
          this.form.get('foodContentInfo').setValue(this.post.foodContentInfo?._id);

          this._changeDetectorRef.detectChanges();
        },
        (error) => {
          this.isLoading = false;
          this.handleError(error);
          this.close();
        }
      );

    // listen for restaurant search field value changes
    this.restaurantServerSideFilteringCtrl.valueChanges
      .pipe(
        filter((search) => !!search),
        tap(() => (this.searching = true)),
        takeUntil(this._unsubscribeAll),
        debounceTime(200),
        switchMap((search) => {
          // simulate server fetching and filtering data
          return this._restaurantService.getRestaurants(1, 20, 'updatedAt', 'desc', search);
        }),
        map((response) => response.data),
        delay(500),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(
        (filteredRestaurants) => {
          this.searching = false;
          // add the default restaurant at first if not exists
          if (
            !filteredRestaurants.some(
              (restaurant) => restaurant && this.post.restaurant && restaurant._id === this.post.restaurant._id
            )
          ) {
            filteredRestaurants.unshift(this.post.restaurant || null);
          }
          this.filteredServerSideRestaurants.next(filteredRestaurants);
        },
        (error) => {
          this.searching = false;
          // handle error...
          this.handleError(error);
        }
      );

    // listen for fci cfhanges
    this.form
      .get('foodContentInfo')
      .valueChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (fciID) => {
          if (this.foodContentInfo.length > 0) {
            this.fciSubcategory = this.foodContentInfo.filter((fci) => fci._id == fciID)[0]?.subcategories;

            // set it as required if have subcategory
            if (this.fciSubcategory && this.fciSubcategory.length > 0) {
              this.form.controls.foodContentInfoSubcategory.setValidators([Validators.required]);
            } else {
              this.form.controls.foodContentInfoSubcategory.clearValidators();
              this.form.get('foodContentInfoSubcategory').patchValue([], { onlySelf: true });
            }
            this.form.controls.foodContentInfoSubcategory.updateValueAndValidity({ onlySelf: true });

            // set the default value if postfci matches selected fciID
            // if (this.post.foodContentInfo._id === fciID) {
            //     this.form.get('foodContentInfoSubcategory').patchValue(this.post.foodContentInfoSubcategory && this.post.foodContentInfoSubcategory.length > 0 ? this.post.foodContentInfoSubcategory.map(d => d._id) : [], { onlySelf: true });
            // }
          }
        },
        (err) => {
          this.handleError(err);
        }
      );
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this._postsService.clearPostObserable();
  }

  save() {
    if (Object.keys(this.getDirtyValues(this.form)).length === 0 && this.image == this.initialImage) {
      this.alert = {
        type: 'warn',
        message: 'No value changed to update.',
      };

      // Show the alert
      this.showAlert = true;
      setTimeout(() => {
        this.showAlert = false;
      }, 5000);
      return;
    }

    // Return if the form is invalid
    if (this.form.invalid) {
      for (let i in this.form.controls) {
        this.form.controls[i].markAsTouched();
      }
      return;
    }

    // Disable the form
    this.form.disable();

    // Hide the alert
    this.showAlert = false;

    this._postsService.updatePost(this.post._id, { ...this.form.value, image: this.image }).subscribe(
      (response) => {
        this.matSnackbar.open(response.message, '', {
          duration: 3000,
          panelClass: ['bg-primary'],
        });
        this.form.enable();
        this.dialogRef.close(true);
      },
      (err) => this.error(err)
    );
  }

  /**
   * Upload image
   *
   * @param fileList
   */
  uploadImage(fileList: FileList): void {
    // Return if canceled
    if (!fileList.length) {
      return;
    }

    const allowedTypes = ['image/jpeg', 'image/png'];
    const file = fileList[0];

    // Return if the file is not allowed
    if (!allowedTypes.includes(file.type)) {
      return;
    }
    from(this._foodaholixService.readAsDataURL(file))
      .pipe(
        map((path) => {
          this.image = path;
          this.showImageBg = false;
        })
      )
      .subscribe();
  }

  /**
   * Remove the image
   */
  removeImage(): void {
    // Set the file input value as null
    this._imageFileInput.nativeElement.value = null;
    this.image = '';
    this.showImageBg = true;
  }

  private error(err: string) {
    this.form.enable();
    // Set the alert
    this.alert = {
      type: 'error',
      message: err,
    };

    // Show the alert
    this.showAlert = true;
  }

  close() {
    this.form.reset();
    this.dialogRef.close(false);
  }

  getDirtyValues(form: any) {
    let dirtyValues = {};

    Object.keys(form.controls).forEach((key) => {
      let currentControl = form.controls[key];

      if (currentControl.dirty) {
        if (currentControl.controls) dirtyValues[key] = this.getDirtyValues(currentControl);
        else dirtyValues[key] = currentControl.value;
      }
    });

    return dirtyValues;
  }

  private handleError(msg: string) {
    this.matSnackbar.open(msg, 'OK', {
      duration: 5000,
      panelClass: ['bg-warn'],
    });
  }

  private successMessage(msg: string) {
    this.matSnackbar.open(msg, '', {
      duration: 3000,
      panelClass: ['bg-primary'],
    });
  }
}
