import { Route } from '@angular/router';
import { AuthGuard, NoAuthGuard } from 'app/core/helpers';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [
  // Redirect empty path to '/example'
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

  // Redirect signed in user to the '/example'
  //
  // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

  // Auth routes for guests
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      // {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
      // {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
      // {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
      {
        path: 'sign-in',
        loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then((m) => m.AuthSignInModule),
      },
      // {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)}
    ],
  },

  // Auth routes for authenticated users
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then((m) => m.AuthSignOutModule),
      },
      // {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)}
    ],
  },

  // Landing routes
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'home',
        loadChildren: () => import('app/modules/landing/home/home.module').then((m) => m.LandingHomeModule),
      },
    ],
  },

  // Admin routes
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: 'countrycitystates',
        loadChildren: () =>
          import('app/modules/admin/countryCityStates/countryCityStates.module').then((m) => m.CountryCityStateModule),
      },
      { path: 'users', loadChildren: () => import('app/modules/admin/users/users.module').then((m) => m.UsersModule) },
      {
        path: 'restaurant',
        loadChildren: () => import('app/modules/admin/restaurant/restaurant.module').then((m) => m.RestaurantModule),
      },
      {
        path: 'cuisine',
        loadChildren: () => import('app/modules/admin/cuisine/cuisine.module').then((m) => m.CuisineModule),
      },
      { path: 'posts', loadChildren: () => import('app/modules/admin/posts/posts.module').then((m) => m.PostsModule) },
      {
        path: 'foodcontentinfo',
        loadChildren: () =>
          import('app/modules/admin/foodContentInfo/foodContentInfo.module').then((m) => m.FoodContentInfoModule),
      },
      {
        path: 'category',
        loadChildren: () => import('app/modules/admin/category/category.module').then((m) => m.CategoryModule),
      },
      {
        path: 'dashboard',
        loadChildren: () => import('app/modules/admin/dashboard/dashboard.module').then((m) => m.DashoboardModule),
      },
      { path: 'config', loadChildren: () => import('app/modules/config/config.module').then((m) => m.ConfigModule) },
      { path: 'helps', loadChildren: () => import('app/modules/admin/helps/helps.module').then((m) => m.HelpModule) },
      {
        path: 'postReports',
        loadChildren: () => import('app/modules/admin/postReports/postReports.module').then((m) => m.PostReportsModule),
      },
      {
        path: 'claimedRewards',
        loadChildren: () =>
          import('app/modules/admin/claimedRewards/claimedRewards.module').then((m) => m.ClaimRewardsModule),
      },
      {
        path: 'claimRestaurants',
        loadChildren: () =>
          import('app/modules/admin/restaurantClaims/restaurantClaims.module').then((m) => m.RestaurantClaimsModule),
      },
      {
        path: 'restaurantReports',
        loadChildren: () =>
          import('app/modules/admin/restaurant-reports/restaurant-reports.module').then(
            (m) => m.RestaurantReportsModule
          ),
      },
      {
        path: 'foodallergies',
        loadChildren: () => import('app/modules/admin/foodAllergy/foodAllergy.module').then((m) => m.FoodAllergyModule),
      },
      {
        path: 'restaurant-ratings-categories',
        loadChildren: () =>
          import('app/modules/admin/restaurant-ratings-categories/restaurant-ratings-categories.module').then(
            (m) => m.RestaurantRatingsCategoriesModel
          ),
      },
      {
        path: 'post-reaction-hashtags',
        loadChildren: () =>
          import('app/modules/admin/post-hashtags/post-hashtags.module').then((m) => m.PostHashtagsModule),
      },
      {
        path: 'auto-notification',
        loadChildren: () =>
          import('app/modules/admin/automatic-notification/automatic-notification.module').then(
            (m) => m.AutomaticNotificationModule
          ),
      },
      {
        path: 'postSearches',
        loadChildren: () =>
          import('app/modules/admin/postSearchDumps/postSearchDumps.module').then((m) => m.PostSearchDumpsModule),
      },
      {
        path: 'fmc',
        loadChildren: () =>
          import('app/modules/admin/foodophileMembershipCode/foodophileMembershipCode.module').then(
            (m) => m.FoodophileMembershipCodeModule
          ),
      },
      {
        path: 'games',
        loadChildren: () => import('app/modules/admin/games/games.module').then((m) => m.GameListModule),
      },
    ],
  },
];
