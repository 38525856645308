import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  // Private
  private _categories: BehaviorSubject<any[] | null> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  get categories$(): Observable<any[]> {
    return this._categories.asObservable();
  }

  getAllCategories(): Observable<any[]> {
    return this._httpClient.get<any>(environment.apiURL + 'category/all').pipe(
      tap((categories) => {
        this._categories.next(categories.categories);
      }),
      map((categories) => {
        return categories.categories;
      })
    );
  }

  create(data: any): Observable<{ category: any; message: string }> {
    return this.categories$.pipe(
      take(1),
      switchMap((allrecords) =>
        this._httpClient.post<{ category: any; message: string }>(environment.apiURL + 'category/add', data).pipe(
          map((newRecord) => {
            // Update the products with the new product
            this._categories.next([newRecord.category, ...allrecords]);

            // Return the new product
            return newRecord;
          })
        )
      )
    );
  }

  update(data: any, id: string): Observable<{ category: any; message: string }> {
    return this.categories$.pipe(
      take(1),
      switchMap((allrecords) =>
        this._httpClient.put<{ category: any; message: string }>(environment.apiURL + 'category/edit/' + id, data).pipe(
          map((updateData) => {
            const index = allrecords.findIndex((item) => item._id === id);

            allrecords[index] = updateData.category;

            this._categories.next(allrecords);

            return updateData;
          })
        )
      )
    );
  }

  enableDisable(id: string): Observable<{ isDisabled: boolean; message: string }> {
    return this.categories$.pipe(
      take(1),
      switchMap((allrecords) =>
        this._httpClient
          .get<{ isDisabled: boolean; message: string }>(environment.apiURL + 'category/enableDisable/' + id)
          .pipe(
            map((updateData) => {
              const index = allrecords.findIndex((item) => item._id === id);

              allrecords[index].isDisabled = updateData.isDisabled;

              this._categories.next(allrecords);

              return updateData;
            })
          )
      )
    );
  }

  public delete(id: string) {
    return this.categories$.pipe(
      take(1),
      switchMap((allrecords) =>
        this._httpClient.delete<any>(environment.apiURL + 'category/delete/' + id).pipe(
          map((response) => {
            // Find the index of the deleted item
            const index = allrecords.findIndex((item) => item._id === id);

            // Delete the item
            allrecords.splice(index, 1);

            // Update the items
            this._categories.next(allrecords);
            return response;
          })
        )
      )
    );
  }
}
