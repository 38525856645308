import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  OnDestroy,
  Input,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CountryCityStatesService } from 'app/core/services';
import { of, ReplaySubject, Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-csc-fields',
  templateUrl: './csc-fields.component.html',
  styleUrls: ['./csc-fields.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CscFieldsComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: CscFieldsComponent,
    },
  ],
})
export class CscFieldsComponent implements OnInit, ControlValueAccessor, OnDestroy, Validator {
  @Input() classes = 'flex flex-col';

  states: any[];
  countries: any[];
  cities: any[];

  public stateFilterCtrl: FormControl = new FormControl();
  public filteredstates: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  public countryFilterCtrl: FormControl = new FormControl();
  public filteredcountries: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  public cityFilterCtrl: FormControl = new FormControl();
  public filteredcities: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  form: FormGroup = this.fb.group({
    country: [null, [Validators.required]],
    state: [null, [Validators.required]],
    city: [null, [Validators.required]],
  });

  onTouched: Function = () => {};

  constructor(
    private fb: FormBuilder,
    private _countryCityStatesService: CountryCityStatesService,
    private matSnackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this._loadCSC('country');

    this.form
      .get('country')
      .valueChanges.pipe(
        takeUntil(this._unsubscribeAll),
        filter((val) => !!val)
      )
      .subscribe((c) => {
        this._loadCSC('state', c);
        this.cities = [];
        this.filteredcities.next(this.cities.slice());
        this.form.get('state').reset();
        this.form.get('city').reset();
      });

    this.form
      .get('state')
      .valueChanges.pipe(
        takeUntil(this._unsubscribeAll),
        filter((val) => !!val)
      )
      .subscribe((c) => {
        this._loadCSC('city', c);
      });

    this.stateFilterCtrl.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((search) => {
      this.filterCSC('states', search);
    });

    this.countryFilterCtrl.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((search) => {
      this.filterCSC('countries', search);
    });

    this.cityFilterCtrl.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((search) => {
      this.filterCSC('cities', search);
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  registerOnChange(onChange: any) {
    this.form.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(onChange);
  }

  registerOnTouched(onTouched: Function) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.form.disable({ emitEvent: false });
    } else {
      this.form.enable({ emitEvent: false });
    }
  }

  writeValue(value: any) {
    if (value) {
      this.form.setValue(value);
    }
  }

  validate(control: AbstractControl) {
    if (this.form.valid) {
      return null;
    }

    let errors: any = {};

    errors = this.addControlErrors(errors, 'country');
    errors = this.addControlErrors(errors, 'state');
    errors = this.addControlErrors(errors, 'city');

    return errors;
  }

  addControlErrors(allErrors: any, controlName: string) {
    const errors = { ...allErrors };

    const controlErrors = this.form.controls[controlName].errors;

    if (controlErrors) {
      errors[controlName] = controlErrors;
    }

    return errors;
  }

  protected filterCSC(type: 'states' | 'countries' | 'cities', search: string) {
    if (!this[type]) {
      return;
    }
    // get the search keyword
    let subjectVarName = `filtered${type}`;
    if (!search) {
      this[subjectVarName].next(this[type].slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this[subjectVarName].next(this[type].filter((t) => t.name.toLowerCase().indexOf(search) > -1));
  }

  private _loadCSC(type: 'country' | 'state' | 'city', parent = '') {
    if (type === 'country' && this.countries && this.countries.length > 0) {
      // as countries will not change frequently so dont load countries if it is already loaded
      return;
    }
    this._countryCityStatesService
      .fetchCSC(1, 1, '', '', type, parent, '', true, true)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (resp) => {
          if (type === 'country') {
            this.countries = resp.data;
            this.filteredcountries.next(this.countries.slice());
          } else if (type === 'state') {
            this.states = resp.data;
            this.filteredstates.next(this.states.slice());
          } else if (type === 'city') {
            this.cities = resp.data;
            this.filteredcities.next(this.cities.slice());
          }
        },
        (error) => {
          this.matSnackbar.open(error, 'OK', {
            duration: 5000,
            panelClass: ['bg-warn'],
          });
        }
      );
  }
}
