<div [formGroup]="form" [ngClass]="classes">
  <mat-form-field>
    <mat-label class="asterix--after">Country</mat-label>
    <mat-select formControlName="country" placeholder="Select country">
      <mat-option>
        <ngx-mat-select-search
          [formControl]="countryFilterCtrl"
          placeholderLabel="Find country..."
          noEntriesFoundLabel="No matching country found"
        ></ngx-mat-select-search>
      </mat-option>

      <mat-option *ngFor="let country of filteredcountries | async" [value]="country._id">
        {{ country.name }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="form.get('country').hasError('required')"> Country is required </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label class="asterix--after">State</mat-label>
    <mat-select formControlName="state" placeholder="Select state">
      <mat-option>
        <ngx-mat-select-search
          [formControl]="stateFilterCtrl"
          placeholderLabel="Find state..."
          noEntriesFoundLabel="No matching state found"
        ></ngx-mat-select-search>
      </mat-option>

      <mat-option *ngFor="let state of filteredstates | async" [value]="state._id">
        {{ state.name }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="form.get('state').hasError('required')"> State is required </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label class="asterix--after">City</mat-label>
    <mat-select formControlName="city" placeholder="Select city">
      <mat-option>
        <ngx-mat-select-search
          [formControl]="cityFilterCtrl"
          placeholderLabel="Find city..."
          noEntriesFoundLabel="No matching city found"
        ></ngx-mat-select-search>
      </mat-option>

      <mat-option *ngFor="let city of filteredcities | async" [value]="city._id">
        {{ city.name }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="form.get('city').hasError('required')"> City is required </mat-error>
  </mat-form-field>
</div>
