import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Post } from '../models';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditPostDialogComponent } from 'app/shared/editPost-dialog/editPost-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class PostsService {
  public allPosts: BehaviorSubject<Post[] | null> = new BehaviorSubject(null);
  private paginatedApiResponse: BehaviorSubject<{ data: Post[]; totalPages: number; totalItems: number } | null> =
    new BehaviorSubject(null);
  private _postDetails: BehaviorSubject<Post | null> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient, private dialog: MatDialog) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get allPosts$(): Observable<Post[]> {
    return this.allPosts.asObservable();
  }

  get paginatedApiResponse$(): Observable<{ data: Post[]; totalPages: number; totalItems: number }> {
    return this.paginatedApiResponse.asObservable();
  }

  get $post(): Observable<Post> {
    return this._postDetails.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getPosts(
    pageNo: number,
    size: number,
    sortBy: string,
    sortDirection: string,
    dishName = '',
    city = '',
    createdBy = '',
    withImage = '',
    restaurant = '',
    category = '',
    noFci = false,
    cuisines = '',
    ids = '',
    nonEnglish = false
  ): Observable<{ data: Post[]; totalPages: number; totalItems: number }> {
    return this._httpClient
      .get<{ data: Post[]; totalPages: number; totalItems: number }>(environment.apiURL + 'post/getAll', {
        params: {
          pageNo,
          size,
          sortBy,
          sortDirection,
          dishName,
          city,
          createdBy,
          withImage,
          restaurant,
          category,
          noFci,
          cuisines,
          ids,
          nonEnglish,
        },
      })
      .pipe(
        tap((response) => {
          this.allPosts.next(response.data);
          this.paginatedApiResponse.next(response);
        })
      );
  }

  updatePost(id: string, data: Post): Observable<{ message: string; post: Post }> {
    return this.allPosts$.pipe(
      take(1),
      switchMap((allPosts) =>
        this._httpClient.put<{ message: string; post: Post }>(environment.apiURL + 'post/update/' + id, data).pipe(
          map((updateData) => {
            if (allPosts) {
              // Find the index of the updated restaurant
              const index = allPosts.findIndex((item) => item._id === id);

              // Update the restaurant
              allPosts[index] = updateData.post;

              // Update the users
              this.allPosts.next(allPosts);
            }

            this._postDetails.next(updateData.post);

            // Return the updated user
            return updateData;
          })
        )
      )
    );
  }

  getPostDetails(id: string): Observable<Post> {
    return this._httpClient.get<{ post: Post; message: string }>(environment.apiURL + 'post/get/' + id).pipe(
      tap((response) => {
        this._postDetails.next(response.post);
      }),
      map((d) => d.post)
    );
  }

  enableDisable(id: string): Observable<{ isDisabled: boolean; message: string }> {
    return this.allPosts$.pipe(
      take(1),
      switchMap((allrecords) =>
        this._httpClient
          .get<{ isDisabled: boolean; message: string }>(environment.apiURL + 'post/enableDisable/' + id)
          .pipe(
            map((updateData) => {
              const index = allrecords.findIndex((item) => item._id === id);

              allrecords[index].isDisabled = updateData.isDisabled;

              this.allPosts.next(allrecords);

              return updateData;
            })
          )
      )
    );
  }

  public delete(id: string) {
    return this.allPosts$.pipe(
      take(1),
      switchMap((allrecords) =>
        this._httpClient.post<any>(environment.apiURL + 'post/delete', { postIds: id }).pipe(
          map((response) => {
            // Find the index of the deleted item
            const index = allrecords.findIndex((item) => item._id === id);

            // Delete the item
            allrecords.splice(index, 1);

            // Update the items
            this.allPosts.next(allrecords);
            return response;
          })
        )
      )
    );
  }

  public openPostDetailsModal(postId: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = false;
    dialogConfig.width = '100vh';

    dialogConfig.data = {
      postId,
    };

    const dialogRef = this.dialog.open(EditPostDialogComponent, dialogConfig);

    return dialogRef.afterClosed();
  }

  clearPostObserable() {
    this._postDetails.next(null);
  }

  public uploadPosts(data: any) {
    return this._httpClient.post<any>(environment.apiURL + 'bulkinsert/posts', data);
  }

  updateCategory(data: { posts: string[]; parentCategory: string }): Observable<{ category: any; message: string }> {
    return this.allPosts$.pipe(
      take(1),
      switchMap((allrecords) =>
        this._httpClient
          .patch<{ category: any; message: string }>(environment.apiURL + 'post/updateCategory', data)
          .pipe(
            map((updateData) => {
              allrecords.forEach((item) => {
                if (data.posts.includes(item._id)) {
                  item.parentCategory = updateData.category;
                }
              });

              this.allPosts.next(allrecords);

              return updateData;
            })
          )
      )
    );
  }

  updateFci(data: { posts: string[]; foodContentInfo: string; foodContentInfoSubcategory: string[] }) {
    return this._httpClient.patch<{ message: string }>(environment.apiURL + 'post/updateFci', data);
  }

  bulkEnableDisable(data: { posts: string[]; isDisabled: boolean }): Observable<{ message: string }> {
    return this.allPosts$.pipe(
      take(1),
      switchMap((allrecords) =>
        this._httpClient.patch<{ message: string }>(environment.apiURL + 'post/bulkEnableDisable', data).pipe(
          map((updateData) => {
            allrecords.forEach((item) => {
              if (data.posts.includes(item._id)) {
                item.isDisabled = data.isDisabled;
              }
            });

            this.allPosts.next(allrecords);

            return updateData;
          })
        )
      )
    );
  }

  download(
    posts: any[],
    pageNo: number,
    size: number,
    sortBy: string,
    sortDirection: string,
    dishName = '',
    city = '',
    createdBy = '',
    withImage = '',
    restaurant = '',
    category = '',
    noFci = false,
    cuisines = '',
    ids = '',
    nonEnglish = false
  ): Observable<any> {
    return this._httpClient
      .post(
        environment.apiURL + 'post/downloadPosts',
        { posts },
        {
          params: {
            pageNo,
            size,
            sortBy,
            sortDirection,
            dishName,
            city,
            createdBy,
            withImage,
            restaurant,
            category,
            noFci,
            cuisines,
            ids,
            nonEnglish,
            isDisabled: false,
          },
          responseType: 'blob',
          observe: 'response',
        }
      )
      .pipe(
        map((res) => {
          // Extract content disposition header
          const contentDisposition = res.headers.get('Content-Disposition');

          // Extract the file name
          const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();

          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename,
          };
          return data;
        })
      );
  }

  public uploadPostsImages(data: any, overwrite: boolean) {
    return this._httpClient.post<any>(environment.apiURL + 'bulkinsert/postsImages', data, {
      params: { overwrite },
    });
  }

  public uploadPostPrices(data: any) {
    return this._httpClient.post<any>(environment.apiURL + 'bulkinsert/postsPrice', data);
  }

  public getPostSearchDumps() {
    return this._httpClient.get<any>(environment.apiURL + 'post/searchDumps');
  }

  fixDishName(data: { postIds: string[]; prefix: string }) {
    return this.allPosts$.pipe(
      take(1),
      switchMap((allrecords) =>
        this._httpClient.patch<Pick<Post, '_id' | 'dishName'>[]>(environment.apiURL + 'post/fixDishName', data).pipe(
          tap((updateData) => {
            for (const d of updateData) {
              const idx = allrecords.findIndex((rec) => rec._id === d._id);

              if (idx !== -1) {
                allrecords[idx].dishName = d.dishName;
              }
            }

            this.allPosts.next(allrecords);
          })
        )
      )
    );
  }
}
