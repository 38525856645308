import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

export enum Configs {
  ADS = 'adConfig',
  APPRECIATE_CONFIG = 'appreciateConfig',
  GOODIES_CONFIG = 'goodiesConfig',
  POST_PAGINATION_SIZE = 'postPaginationSize',
  NOTI_PAGINATION_SIZE = 'notiPaginationSize',
  FAV_RES_PAGINATION_SIZE = 'favResPaginationSize',
  SUBSCRIPTION_NOTE = 'subscriptionNote',
  CUSTOMMESSAGE_CHARACTERS_LIMIT = 'customMessageCharacters',
  ADS_AFTER_POST = 'adsafterpost',
  SUBSCRIPTION_AMT = 'subscriptionAmt',
  GOODIES_LIMIT = 'goodiesLimit',
  WELCOME_MESSAGE = 'welcomeMessage',
  DELETE_ACCNT_MSG = 'deleteAcMsg',
}

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  // Private
  private _dashboardData: BehaviorSubject<any | null> = new BehaviorSubject(null);
  private _allConfigs: BehaviorSubject<any | null> = new BehaviorSubject(null);
  private _unifiedConfigs: BehaviorSubject<any | null> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  get dashboardData$(): Observable<any> {
    return this._dashboardData.asObservable();
  }

  get allConfigs$(): Observable<any[]> {
    return this._allConfigs.asObservable();
  }

  get unifiedConfigs$(): Observable<any> {
    return this._unifiedConfigs.asObservable();
  }

  getDashboardData(): Observable<any> {
    return this._httpClient.get<any>(environment.apiURL + 'config/dashboardData').pipe(
      tap((foodContentInfos) => {
        this._dashboardData.next(foodContentInfos);
      })
    );
  }

  getAllConfigs(): Observable<any> {
    return this._httpClient.get<any>(environment.apiURL + 'config/all').pipe(
      tap((configs) => {
        this._allConfigs.next(configs.config);
      })
    );
  }

  createGoodieLimits(data: any): Observable<any> {
    return this._httpClient.post<any>(environment.apiURL + 'config/setGoodies', data);
  }

  createBlacklistWords(data: any): Observable<any> {
    return this._httpClient.post<any>(environment.apiURL + 'config/blacklistWords', data);
  }

  /**
   * Create reward
   */
  createReward(data: FormData): Observable<any> {
    return this._httpClient.post<any>(environment.apiURL + 'rewards/create', data);
  }

  /**
   * Create report reason
   */
  createReportReason(data: any): Observable<any> {
    return this._httpClient.post<any>(environment.apiURL + 'report/addReason', data);
  }

  private updateConfigStream = (id: string, allConfigs: any[], updateData: any, isDelete: boolean = false) => {
    const index = allConfigs.findIndex((item) => item._id === id);

    if (isDelete) {
      allConfigs.splice(index, 1);
    } else {
      allConfigs[index] = updateData.config;
    }

    this._allConfigs.next(allConfigs);

    return updateData;
  };

  updateConfig(data: any, id: string): Observable<any> {
    return this.allConfigs$.pipe(
      take(1),
      switchMap((allConfigs) =>
        this._httpClient
          .put<any>(environment.apiURL + 'config/update/' + id, data)
          .pipe(map((updateData) => this.updateConfigStream(id, allConfigs, updateData)))
      )
    );
  }

  updateReward(data: FormData, id: string): Observable<any> {
    return this.allConfigs$.pipe(
      take(1),
      switchMap((allConfigs) =>
        this._httpClient
          .put<any>(environment.apiURL + 'rewards/update/' + id, data)
          .pipe(map((updateData) => this.updateConfigStream(id, allConfigs, updateData)))
      )
    );
  }

  enableDisable(id: string): Observable<any> {
    return this.allConfigs$.pipe(
      take(1),
      switchMap((allConfigs) =>
        this._httpClient.get<any>(environment.apiURL + 'config/enableDisable/' + id).pipe(
          map((updateData) => {
            // Find the index of the updated product
            const index = allConfigs.findIndex((item) => item._id === id);

            // Update the product
            allConfigs[index].isDisabled = updateData.isDisabled;

            // Update the products
            this._allConfigs.next(allConfigs);

            // Return the updated product
            return updateData;
          })
        )
      )
    );
  }

  deleteConfig(id: string): Observable<any> {
    return this.allConfigs$.pipe(
      take(1),
      switchMap((allConfigs) =>
        this._httpClient
          .delete<any>(environment.apiURL + 'config/delete/' + id)
          .pipe(map((updateData) => this.updateConfigStream(id, allConfigs, updateData, true)))
      )
    );
  }

  deleteRewardIcons(id: string, body: any): Observable<any> {
    return this.allConfigs$.pipe(
      take(1),
      switchMap((allConfigs) =>
        this._httpClient
          .post<any>(environment.apiURL + 'rewards/deleteIcons/' + id, body)
          .pipe(map((updateData) => this.updateConfigStream(id, allConfigs, updateData)))
      )
    );
  }

  createUpdateAds(data: any): Observable<any> {
    return this._httpClient.post<any>(environment.apiURL + 'config/adsConfig', data);
  }

  createUpdateConfig(data: any): Observable<any> {
    return this._httpClient.post<any>(environment.apiURL + 'config/createUpdateConfig', data);
  }

  getUnifiedConfigs(): Observable<any> {
    return this._httpClient.get<any>(environment.apiURL + 'config/unified').pipe(
      tap((configs) => {
        this._unifiedConfigs.next(configs);
      })
    );
  }

  createRestaurantReportReason(data: any): Observable<any> {
    return this._httpClient.post<any>(environment.apiURL + 'report/addRestReason', data);
  }

  createAppRelease(device: string, releaseDetails: any): Observable<{ message: string; details: any }> {
    return this._httpClient.post<{ message: string; details: any }>(environment.apiURL + 'config/createAppRelease', {
      device,
      releaseDetails,
    });
  }
}
