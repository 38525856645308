import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { appInitializer, ErrorInterceptor, JwtInterceptor } from 'app/core/helpers';
import {
  AuthService,
  CategoryService,
  ClaimRestaurantService,
  ConfigService,
  CountryCityStatesService,
  CuisineService,
  FoodaholixService,
  FoodAllergyService,
  FoodContentInfoService,
  GoodiesService,
  HelpService,
  RestaurantService,
  RewardsService,
  PostReactionHashtagsService,
  AutomaticNotificationService,
  FoodophileMembershipCodeService,
  RestaurantRatingsCategoryService,
  GamesService,
} from 'app/core/services';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    AuthService,
    CountryCityStatesService,
    FoodaholixService,
    RestaurantService,
    CuisineService,
    FoodContentInfoService,
    CategoryService,
    ConfigService,
    RewardsService,
    HelpService,
    GoodiesService,
    ClaimRestaurantService,
    FoodAllergyService,
    RestaurantRatingsCategoryService,
    PostReactionHashtagsService,
    AutomaticNotificationService,
    FoodophileMembershipCodeService,
    GamesService,
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
})
export class CoreModule {
  /**
   * Constructor
   */
  constructor(
    private _domSanitizer: DomSanitizer,
    private _matIconRegistry: MatIconRegistry,
    @Optional() @SkipSelf() parentModule?: CoreModule
  ) {
    // Do not allow multiple injections
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
    }

    // Register icon sets
    this._matIconRegistry.addSvgIconSet(
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-twotone.svg')
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'mat_outline',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-outline.svg')
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'mat_solid',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-solid.svg')
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'iconsmind',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/iconsmind.svg')
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'feather',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/feather.svg')
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'heroicons_outline',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-outline.svg')
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'heroicons_solid',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-solid.svg')
    );
    this._matIconRegistry.addSvgIcon(
      'cuisine',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/cuisine.svg')
    );
    this._matIconRegistry.addSvgIcon(
      'review',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/review.svg')
    );
  }
}
