import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators, FormGroup, NgForm, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseAlertType } from '@fuse/components/alert';
import { FuseAnimations } from '@fuse/animations';
import { GoodiesService } from 'app/core/services';
import { Goodie } from 'app/core/models';

@Component({
  selector: 'addUserGoodie-dialog',
  templateUrl: './addUserGoodie-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: FuseAnimations,
})
export class AddUserGoodieDialogComponent implements OnInit {
  form: FormGroup;
  data: { userId: string; username: string; type: string; editDoc?: Goodie };
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: '',
  };
  showAlert: boolean = false;

  constructor(
    fb: FormBuilder,
    private dialogRef: MatDialogRef<AddUserGoodieDialogComponent>,
    private _goodiesService: GoodiesService,
    private _changeDetectorRef: ChangeDetectorRef,
    private matSnackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.data = data;
    if (this.data.type === 'Edit') {
      this.form = fb.group({
        coin: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
        reason: [''],
      });
    } else {
      this.form = fb.group({
        coin: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
        emailSubject: ['', Validators.required],
        emailBody: ['', Validators.required],
        reason: [''],
      });
    }
  }

  ngOnInit() {
    if (this.data.type === 'Edit') {
      // Fill the form
      this.form.patchValue({
        coin: this.data.editDoc.coinsEarned || '',
        reason: this.data.editDoc.reason || '',
      });

      // Mark for check
      this._changeDetectorRef.markForCheck();
    }
  }

  save() {
    if (this.data.type === 'Edit' && Object.keys(this.getDirtyValues(this.form)).length === 0) {
      this.alert = {
        type: 'warn',
        message: 'No value changed to update.',
      };

      // Show the alert
      this.showAlert = true;
      setTimeout(() => {
        this.showAlert = false;
      }, 3000);
      return;
    }
    // Return if the form is invalid
    if (this.form.invalid) {
      for (let i in this.form.controls) {
        this.form.controls[i].markAsTouched();
      }
      return;
    }

    // Disable the form
    this.form.disable();

    // Hide the alert
    this.showAlert = false;

    if (this.data.type === 'Add') {
      this._goodiesService.adminAddGoodie({ ...this.form.value, userId: this.data.userId }).subscribe(
        (response) => {
          this.form.reset();
          this.matSnackbar.open(response.message, '', {
            duration: 3000,
            panelClass: ['bg-primary'],
          });
          this.dialogRef.close(true);
        },
        (err) => this.error(err)
      );
    } else if (this.data.type === 'Edit') {
      this._goodiesService.adminUpdateGoodie({ ...this.form.value, goodieId: this.data.editDoc._id }).subscribe(
        (response) => {
          this.form.reset();
          this.matSnackbar.open(response.message, '', {
            duration: 3000,
            panelClass: ['bg-primary'],
          });
          this.dialogRef.close(true);
        },
        (err) => this.error(err)
      );
    }
  }

  private error(err: string) {
    this.form.enable();
    // Set the alert
    this.alert = {
      type: 'error',
      message: err,
    };

    // Show the alert
    this.showAlert = true;
  }

  close() {
    this.dialogRef.close(false);
  }

  getDirtyValues(form: any) {
    let dirtyValues = {};

    Object.keys(form.controls).forEach((key) => {
      let currentControl = form.controls[key];

      if (currentControl.dirty) {
        if (currentControl.controls) dirtyValues[key] = this.getDirtyValues(currentControl);
        else dirtyValues[key] = currentControl.value;
      }
    });

    return dirtyValues;
  }
}
