import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseAlertModule } from '@fuse/components/alert';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AddUserGoodieDialogComponent } from './addGoodie-dialog/addUserGoodie-dialog.component';
import { ConfirmationPopupComponent } from './confirmationPopup/confirmationPopup.component';
import { EditPostDialogComponent } from './editPost-dialog/editPost-dialog.component';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { NFormatter } from './pipes/nFormatter.pipe';
import { SecurePipe } from './pipes/secure.pipe';
import { ShowTextDialogComponent } from './showText-dialog/showText-dialog.component';
import { UsersDetailsComponent } from './userDetails/userDetails.component';
import { UserGoodieLogDialogComponent } from './userGoodieLog-dialog/userGoodieLog-dialog.component';
import { StarsComponent } from './stars/stars.component';
import { CscFieldsComponent } from './csc-fields/csc-fields.component';

@NgModule({
  declarations: [
    ConfirmationPopupComponent,
    UsersDetailsComponent,
    EditPostDialogComponent,
    AddUserGoodieDialogComponent,
    UserGoodieLogDialogComponent,
    ShowTextDialogComponent,
    NFormatter,
    ImageViewerComponent,
    SecurePipe,
    StarsComponent,
    CscFieldsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSortModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatTableModule,
    MatMenuModule,
    NgxMatSelectSearchModule,
    FuseAlertModule,
    ReactiveFormsModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UsersDetailsComponent,
    NFormatter,
    SecurePipe,
    StarsComponent,
    CscFieldsComponent,
  ],
})
export class SharedModule {}
