import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { Game, GameInputDTO, GamePremiumFeatureInputDTO, GamePremiumFeatures, PopulatedGame } from '../models';

@Injectable({
  providedIn: 'root',
})
export class GamesService {
  private _gameList: BehaviorSubject<Game[]> = new BehaviorSubject([]);
  private _gameFeatures: BehaviorSubject<GamePremiumFeatures[]> = new BehaviorSubject([]);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get gameList$(): Observable<Game[]> {
    return this._gameList.asObservable();
  }

  get gameFeatures$(): Observable<GamePremiumFeatures[]> {
    return this._gameFeatures.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getGames(): Observable<Game[]> {
    return this._httpClient.get<Game[]>(environment.apiURL + 'game/all').pipe(
      tap((response) => {
        this._gameList.next(response);
      })
    );
  }

  create(data: GameInputDTO): Observable<{ message: string; game: Game }> {
    return this.gameList$.pipe(
      take(1),
      switchMap((games) =>
        this._httpClient.post<{ message: string; game: Game }>(environment.apiURL + 'game/create', data).pipe(
          tap((response) => {
            this._gameList.next([response.game, ...games]);
          })
        )
      )
    );
  }

  update(data: Game, id: string): Observable<{ message: string; game: Game }> {
    return this.gameList$.pipe(
      take(1),
      switchMap((games) =>
        this._httpClient.put<{ message: string; game: Game }>(environment.apiURL + 'game/update/' + id, data).pipe(
          tap((updateData) => {
            const index = games.findIndex((item) => item._id === id);

            if (index !== -1) {
              games[index] = updateData.game;

              this._gameList.next(games);
            }
          })
        )
      )
    );
  }

  addWinners(
    data: { winners: Game['winners']; isNotifyAll?: boolean },
    id: string
  ): Observable<{ message: string; game: Game }> {
    return this.gameList$.pipe(
      take(1),
      switchMap((games) =>
        this._httpClient.post<{ message: string; game: Game }>(environment.apiURL + 'game/addWinners/' + id, data).pipe(
          tap((updateData) => {
            const index = games.findIndex((item) => item._id === id);

            if (index !== -1) {
              games[index] = updateData.game;

              this._gameList.next(games);
            }
          })
        )
      )
    );
  }

  details(gameId: string): Observable<PopulatedGame> {
    return this._httpClient.get<PopulatedGame>(environment.apiURL + 'game/details/' + gameId);
  }

  enableDisable(data: {
    gameId: string;
    isRefundGoodies?: boolean;
  }): Observable<{ isActive: boolean; message: string }> {
    return this.gameList$.pipe(
      take(1),
      switchMap((allRecords) =>
        this._httpClient
          .patch<{ isActive: boolean; message: string }>(environment.apiURL + 'game/enableDisable', data)
          .pipe(
            tap((updateData) => {
              const index = allRecords.findIndex((item) => item._id === data.gameId);

              allRecords[index].isActive = updateData.isActive;

              this._gameList.next(allRecords);
            })
          )
      )
    );
  }

  updatePrize(data: FormData): Observable<{ message: string; game: PopulatedGame }> {
    return this._httpClient.patch<{ message: string; game: PopulatedGame }>(
      environment.apiURL + 'game/updatePrize',
      data
    );
  }

  delPrize(data: { gameId: string; position: number }) {
    return this._httpClient.delete<{ message: string }>(
      environment.apiURL + `game/delPrize?gameId=${data.gameId}&position=${data.position}`
    );
  }

  getGameFeatures(): Observable<GamePremiumFeatures[]> {
    return this._httpClient.get<{ data: GamePremiumFeatures[] }>(environment.apiURL + 'game/premiumFeature').pipe(
      map((response) => {
        this._gameFeatures.next(response.data);

        return response.data;
      })
    );
  }

  createFeature(data: FormData): Observable<{ message: string; feature: GamePremiumFeatures }> {
    return this.gameFeatures$.pipe(
      take(1),
      switchMap((features) =>
        this._httpClient
          .post<{ message: string; feature: GamePremiumFeatures }>(environment.apiURL + 'game/premiumFeature/add', data)
          .pipe(
            tap((response) => {
              features.push(response.feature);
              this._gameFeatures.next(features);
            })
          )
      )
    );
  }

  updateFeature(data: FormData): Observable<{ message: string; feature: GamePremiumFeatures }> {
    return this.gameFeatures$.pipe(
      take(1),
      switchMap((features) =>
        this._httpClient
          .put<{ message: string; feature: GamePremiumFeatures }>(environment.apiURL + 'game/premiumFeature/edit', data)
          .pipe(
            tap((updateData) => {
              const index = features.findIndex((item) => item._id === data.get('featureId'));

              if (index !== -1) {
                features[index] = updateData.feature;

                this._gameFeatures.next(features);
              }
            })
          )
      )
    );
  }

  public deleteFeature(id: string) {
    return this.gameFeatures$.pipe(
      take(1),
      switchMap((allRecords) =>
        this._httpClient.delete<any>(environment.apiURL + 'game/premiumFeature/del/' + id).pipe(
          map((response) => {
            // Find the index of the deleted item
            const index = allRecords.findIndex((item) => item._id === id);

            // Delete the item
            allRecords.splice(index, 1);

            // Update the items
            this._gameFeatures.next(allRecords);
            return response;
          })
        )
      )
    );
  }
}
