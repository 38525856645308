import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FoodAllergyService {
  // Private
  private _foodAllergies: BehaviorSubject<any[] | null> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get foodAllergies$(): Observable<any[]> {
    return this._foodAllergies.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getFoodAllergies(name = ''): Observable<any[]> {
    return this._httpClient
      .get<any>(environment.apiURL + 'allergy/all', {
        params: { name },
      })
      .pipe(
        tap((data) => {
          this._foodAllergies.next(data.foodAllergies);
        }),
        map((data) => {
          return data.foodAllergies;
        })
      );
  }

  createFoodAllergy(data: { name: string }): Observable<any> {
    return this.foodAllergies$.pipe(
      take(1),
      switchMap((allergies) =>
        this._httpClient.post<any>(environment.apiURL + 'allergy/add', data).pipe(
          map((newAllergy) => {
            this._foodAllergies.next([newAllergy.foodAllergy, ...allergies]);

            return newAllergy;
          })
        )
      )
    );
  }

  updateFoodAllergy(data: { name: string }, id: string): Observable<any> {
    return this.foodAllergies$.pipe(
      take(1),
      switchMap((allergies) =>
        this._httpClient.put<any>(environment.apiURL + 'allergy/edit/' + id, data).pipe(
          map((updateData) => {
            const index = allergies.findIndex((item) => item._id === id);

            allergies[index] = updateData.foodAllergy;

            this._foodAllergies.next(allergies);

            return updateData;
          })
        )
      )
    );
  }

  public delete(id: string, forceDelete: boolean = false) {
    return this.foodAllergies$.pipe(
      take(1),
      switchMap((allergies) =>
        this._httpClient
          .delete<any>(environment.apiURL + 'allergy/del/' + id, {
            params: { forceDelete },
            headers: { skip: 'true' },
          })
          .pipe(
            map((response) => {
              const index = allergies.findIndex((item) => item._id === id);

              allergies.splice(index, 1);

              this._foodAllergies.next(allergies);
              return response;
            })
          )
      )
    );
  }
}
