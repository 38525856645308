import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RewardsService {
  // Private
  private _rewardCombs: BehaviorSubject<any[] | null> = new BehaviorSubject(null);
  private _allClaimedRewards: BehaviorSubject<any[] | null> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get rewardCombos$(): Observable<any[]> {
    return this._rewardCombs.asObservable();
  }

  get allClaimedRewards$(): Observable<any[]> {
    return this._allClaimedRewards.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getRewardCombos(): Observable<any[]> {
    return this._httpClient.get<any>(environment.apiURL + 'rewards/allCombo').pipe(
      tap((response) => {
        this._rewardCombs.next(response.combos);
      }),
      map((response) => {
        return response.combos;
      })
    );
  }

  /**
   * Create rewardCombo
   */
  createRewardCombo(data: any): Observable<any> {
    return this._httpClient.post<any>(environment.apiURL + 'rewards/createCombo', data);
  }

  /**
   * update reward combo
   */
  updateRewardCombo(data: any, id: string): Observable<any> {
    return this._httpClient.put<any>(environment.apiURL + 'rewards/updateCombo/' + id, data);
  }

  enableDisable(id: string): Observable<any> {
    return this.rewardCombos$.pipe(
      take(1),
      switchMap((combos) =>
        this._httpClient.get<any>(environment.apiURL + 'rewards/enableDisable/' + id).pipe(
          map((updateData) => {
            const index = combos.findIndex((item) => item._id === id);

            combos[index].isDisabled = updateData.isDisabled;

            this._rewardCombs.next(combos);

            return updateData;
          })
        )
      )
    );
  }

  public delete(id: string) {
    return this.rewardCombos$.pipe(
      take(1),
      switchMap((allrecords) =>
        this._httpClient.delete<any>(environment.apiURL + 'rewards/delete/' + id).pipe(
          map((response) => {
            // Find the index of the deleted item
            const index = allrecords.findIndex((item) => item._id === id);

            // Delete the item
            allrecords.splice(index, 1);

            // Update the items
            this._rewardCombs.next(allrecords);
            return response;
          })
        )
      )
    );
  }

  public allClaimedRewards() {
    return this._httpClient.get<any>(environment.apiURL + 'claim/all').pipe(
      tap((response) => {
        this._allClaimedRewards.next(response.claims);
      }),
      map((response) => {
        return response.claims;
      })
    );
  }
}
