import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FoodaholixService } from 'app/core/services';
import { Download } from 'app/core/services/download';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ImageViewerComponent implements OnInit {
  imageUrl: string;
  title: string;
  downloadImage = true;
  downloadProgress$: Observable<Download>;

  constructor(
    public dialogRef: MatDialogRef<ImageViewerComponent>,
    @Inject(MAT_DIALOG_DATA) { imageUrl, title, downloadImage },
    private _foodaholixService: FoodaholixService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    this.imageUrl = imageUrl;
    this.title = title;
    this.downloadImage = downloadImage;
  }

  ngOnInit() {
    this.downloadProgress$ = this._foodaholixService.downloadProgress$;
    this._changeDetectorRef.detectChanges();
  }
}
