import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FoodophileMembershipCodeService {
  // Private
  private _codes: BehaviorSubject<any[] | null> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  get codes$(): Observable<any[]> {
    return this._codes.asObservable();
  }

  getAllCodes(): Observable<any[]> {
    return this._httpClient.get<any>(environment.apiURL + 'foodophileMembership/getAllCodes').pipe(
      tap((resp) => {
        this._codes.next(resp.codes);
      }),
      map((resp) => {
        return resp.codes;
      })
    );
  }

  create(data: any): Observable<{ codes: string; message: string }> {
    return this._httpClient.post<{ codes: string; message: string }>(
      environment.apiURL + 'foodophileMembership/createCodes',
      data
    );
  }
}
