import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { ClaimRestaurant, SubcriptionDetails } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ClaimRestaurantService {
  private apiURL = environment.apiURL + 'claimRestaurant/';

  private _allClaims: BehaviorSubject<ClaimRestaurant[] | null> = new BehaviorSubject(null);
  private paginatedApiResponse: BehaviorSubject<{
    data: ClaimRestaurant[];
    totalPages: number;
    totalItems: number;
  } | null> = new BehaviorSubject(null);
  private _subscriptions: BehaviorSubject<SubcriptionDetails[] | null> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get allClaims$(): Observable<ClaimRestaurant[]> {
    return this._allClaims.asObservable();
  }

  get paginatedApiResponse$(): Observable<{ data: ClaimRestaurant[]; totalPages: number; totalItems: number }> {
    return this.paginatedApiResponse.asObservable();
  }

  get subscriptions$(): Observable<SubcriptionDetails[]> {
    return this._subscriptions.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getClaims(
    pageNo: number,
    size: number,
    sortBy: string,
    sortDirection: string,
    userID = '',
    restaurantID = '',
    status = ''
  ): Observable<{ data: ClaimRestaurant[]; totalPages: number; totalItems: number }> {
    return this._httpClient
      .get<{ data: ClaimRestaurant[]; totalPages: number; totalItems: number }>(this.apiURL + 'getAll', {
        params: {
          pageNo,
          size,
          sortBy,
          sortDirection,
          userID,
          restaurantID,
          status,
        },
      })
      .pipe(
        tap((response) => {
          this._allClaims.next(response.data);
          this.paginatedApiResponse.next(response);
        })
      );
  }

  changeClaimStatus(data: {
    claimId: string;
    status: string;
    reason: string;
  }): Observable<{ message: string; claim: ClaimRestaurant }> {
    return this.allClaims$.pipe(
      take(1),
      switchMap((allClaims) =>
        this._httpClient.post<{ message: string; claim: ClaimRestaurant }>(this.apiURL + 'adminChangeClaim', data).pipe(
          map((updateData) => {
            if (allClaims) {
              // Find the index of the updated claim
              const index = allClaims.findIndex((item) => item._id === data.claimId);

              allClaims[index] = updateData.claim;

              this._allClaims.next(allClaims);
            }

            // Return the updated claim
            return updateData;
          })
        )
      )
    );
  }

  getSubcriptionByClaim(claimID: string): Observable<SubcriptionDetails[]> {
    return this._httpClient.get<SubcriptionDetails[]>(this.apiURL + 'subcription/' + claimID).pipe(
      tap((response) => {
        this._subscriptions.next(response);
      })
    );
  }

  public deleteClaim(id: string) {
    return this.allClaims$.pipe(
      take(1),
      switchMap((allrecords) =>
        this._httpClient.delete<{ message: string }>(this.apiURL + 'delClaim/' + id).pipe(
          map((response) => {
            // Find the index of the deleted item
            const index = allrecords.findIndex((item) => item._id === id);

            // Delete the item
            allrecords.splice(index, 1);

            // Update the items
            this._allClaims.next(allrecords);
            return response;
          })
        )
      )
    );
  }

  public adminEditCustomMessage(data: { claimId: string; customMessage: string }) {
    return this.allClaims$.pipe(
      take(1),
      switchMap((allrecords) =>
        this._httpClient.patch<{ message: string }>(this.apiURL + 'adminEditCustomMessage', data).pipe(
          map((response) => {
            const index = allrecords.findIndex((item) => item._id === data.claimId);

            if (index !== -1) {
              allrecords[index].customMessage = data.customMessage;
            }

            this._allClaims.next(allrecords);
            return response;
          })
        )
      )
    );
  }

  public updateSubscriptionStatus(data: any) {
    return this._httpClient.patch<{ message: string }>(this.apiURL + 'updateSubscriptionStatus', data);
  }

  public retriggerNotification(data: any) {
    return this._httpClient.patch<{ message: string }>(this.apiURL + 'retriggerNotifications', data);
  }
}
