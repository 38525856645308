import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Report, ReportRestaurant } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private _postReports: BehaviorSubject<Report[] | null> = new BehaviorSubject(null);
  private paginatedApiResponse: BehaviorSubject<{ data: Report[]; totalPages: number; totalItems: number } | null> =
    new BehaviorSubject(null);
  private _restaurantReports: BehaviorSubject<{
    data: ReportRestaurant[];
    totalPages: number;
    totalItems: number;
  } | null> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get postReports$(): Observable<Report[]> {
    return this._postReports.asObservable();
  }

  get restaurantReports$(): Observable<{ data: ReportRestaurant[]; totalPages: number; totalItems: number }> {
    return this._restaurantReports.asObservable();
  }

  get paginatedApiResponse$(): Observable<{ data: Report[]; totalPages: number; totalItems: number }> {
    return this.paginatedApiResponse.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getPostReports(
    pageNo: number,
    size: number,
    sortBy: string,
    sortDirection: string,
    userid: string
  ): Observable<{ data: Report[]; totalPages: number; totalItems: number }> {
    return this._httpClient
      .get<{ data: Report[]; totalPages: number; totalItems: number }>(environment.apiURL + 'report/posts/all', {
        params: {
          pageNo,
          size,
          sortBy,
          sortDirection,
        },
      })
      .pipe(
        tap((response) => {
          this._postReports.next(response.data);
          this.paginatedApiResponse.next(response);
        })
      );
  }

  getRestaurantReports(
    pageNo: number,
    size: number,
    sortBy: string,
    sortDirection: string
  ): Observable<{ data: ReportRestaurant[]; totalPages: number; totalItems: number }> {
    return this._httpClient
      .get<{ data: ReportRestaurant[]; totalPages: number; totalItems: number }>(
        environment.apiURL + 'report/restaurant/all',
        {
          params: {
            pageNo,
            size,
            sortBy,
            sortDirection,
          },
        }
      )
      .pipe(
        tap((response) => {
          this._restaurantReports.next(response);
        })
      );
  }
}
