<h2 mat-dialog-title>{{ data.type }} Goodie for user: {{ data.username }}</h2>

<mat-dialog-content>
  <!-- Alert -->
  <fuse-alert
    *ngIf="showAlert"
    [appearance]="'outline'"
    [showIcon]="false"
    [type]="alert.type"
    [@shake]="alert.type === 'error'"
    [dismissible]="true"
    [dismissed]="false"
  >
    {{ alert.message }}
  </fuse-alert>

  <form [formGroup]="form" #dataForm="ngForm" (ngSubmit)="save()">
    <div class="flex flex-col gt-xs:flex-row">
      <mat-form-field class="w-100 flex-auto gt-xs:pr-3" [floatLabel]="'always'">
        <mat-label>Goodies</mat-label>
        <input matInput placeholder="Enter goodies" formControlName="coin" required />
        <mat-error *ngIf="form.get('coin').hasError('required')"> Goodies is required </mat-error>
        <mat-error *ngIf="form.get('coin').hasError('pattern')"> Goodies must be only numbers </mat-error>
      </mat-form-field>

      <mat-form-field class="w-100 flex-auto gt-xs:pr-3" [floatLabel]="'always'" *ngIf="data?.type !== 'Edit'">
        <mat-label>Email Subject</mat-label>
        <input matInput placeholder="Enter email subject" formControlName="emailSubject" required />
        <mat-error *ngIf="form.get('emailSubject').hasError('required')"> Email subject is required </mat-error>
      </mat-form-field>

      <mat-form-field
        class="w-100 flex-auto gt-xs:pr-3 fuse-mat-textarea"
        [floatLabel]="'always'"
        *ngIf="data?.type !== 'Edit'"
      >
        <mat-label>Email Body</mat-label>
        <textarea
          matInput
          [rows]="3"
          [formControlName]="'emailBody'"
          placeholder="Enter email body"
          required
        ></textarea>
        <mat-error *ngIf="form.get('emailBody').hasError('required')"> Email body is requried </mat-error>
      </mat-form-field>

      <mat-form-field class="w-100 flex-auto gt-xs:pr-3" [floatLabel]="'always'">
        <mat-label>Reason</mat-label>
        <input matInput placeholder="Enter reason" formControlName="reason" />
        <mat-hint>Reason for adding this goodie</mat-hint>
      </mat-form-field>

      <button type="submit" hidden #btn></button>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button (click)="close()">Close</button>

  <button class="mat-raised-button" mat-button [color]="'primary'" [disabled]="form.disabled" (click)="save()">
    <span *ngIf="!form.disabled">
      {{ data.type === 'Add' ? 'Save' : 'Update' }}
    </span>
    <mat-progress-spinner *ngIf="form.disabled" [diameter]="24" [mode]="'indeterminate'"> </mat-progress-spinner>
  </button>
</mat-dialog-actions>
