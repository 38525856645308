import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CountryCityStatesService {
  // Private
  private _countryCityStates: BehaviorSubject<any[] | null> = new BehaviorSubject(null);
  private _cities: BehaviorSubject<any[] | null> = new BehaviorSubject(null);
  private _newCountryCityStates: BehaviorSubject<any | null> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for countryCityStates
   */
  get countryCityStates$(): Observable<any[]> {
    return this._countryCityStates.asObservable();
  }

  get cities$(): Observable<any[]> {
    return this._cities.asObservable();
  }

  get newCountryCityStates$(): Observable<any> {
    return this._newCountryCityStates.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get countryCityStates
   */
  getCountryCityStates(type = '', id = ''): Observable<any> {
    let url = environment.apiURL + 'cityStatesCountry/all';
    if (type) {
      url += '/' + type;
      if (id) {
        url += '/' + id;
      }
    }
    return this._httpClient.get<any>(url).pipe(
      tap((countryCityStates) => {
        this._countryCityStates.next(countryCityStates.data);
      })
    );
  }

  /**
   * Create countryCityStates
   */
  createCountryCityStates(data: any): Observable<any> {
    return this.newCountryCityStates$.pipe(
      take(1),
      switchMap((countryCityStates) =>
        this._httpClient.post<any>(environment.apiURL + 'cityStatesCountry/add', data).pipe(
          map((newCountryCityStates) => {
            countryCityStates.data = [newCountryCityStates.data, ...countryCityStates.data];

            // Update the products with the new product
            this._newCountryCityStates.next(countryCityStates);

            // Return the new product
            return newCountryCityStates;
          })
        )
      )
    );
  }

  /**
   * update countryCityStates
   */
  updateCountryCityStates(data: any, id: string): Observable<any> {
    return this.newCountryCityStates$.pipe(
      take(1),
      switchMap((countryCityStates) =>
        this._httpClient.put<any>(environment.apiURL + 'cityStatesCountry/edit/' + id, data).pipe(
          map((updateData) => {
            // Find the index of the updated product
            const index = countryCityStates.data.findIndex((item) => item._id === id);

            // Update the product
            countryCityStates.data[index] = updateData.data;

            // Update the products
            this._newCountryCityStates.next(countryCityStates);

            // Return the updated product
            return updateData;
          })
        )
      )
    );
  }

  public delete(id: string) {
    return this.newCountryCityStates$.pipe(
      take(1),
      switchMap((countryCityStates) =>
        this._httpClient.delete<any>(environment.apiURL + 'cityStatesCountry/delete/' + id).pipe(
          map((response) => {
            // Find the index of the deleted product
            const index = countryCityStates.data.findIndex((item) => item._id === id);

            // Delete the product
            countryCityStates.data.splice(index, 1);

            // Update the products
            this._newCountryCityStates.next(countryCityStates);
            return response;
          })
        )
      )
    );
  }

  /**
   * update countryCityStates
   */
  enableDisable(id: string): Observable<any> {
    return this.newCountryCityStates$.pipe(
      take(1),
      switchMap((countryCityStates) =>
        this._httpClient.get<any>(environment.apiURL + 'cityStatesCountry/enableDisable/' + id).pipe(
          map((updateData) => {
            // Find the index of the updated product
            const index = countryCityStates.data.findIndex((item) => item._id === id);

            // Update the product
            countryCityStates.data[index].isDisabled = updateData.isDisabled;

            // Update the products
            this._newCountryCityStates.next(countryCityStates);

            // Return the updated product
            return updateData;
          })
        )
      )
    );
  }

  /**
   * Search cities by name
   */
  getCitites(name = ''): Observable<any[]> {
    return this._httpClient
      .get<any>(environment.apiURL + 'cityStatesCountry/searchCities', {
        params: { name },
      })
      .pipe(
        tap((response) => {
          this._cities.next(response.cities);
        }),
        map((response) => {
          return response.cities;
        })
      );
  }

  fetchCSC(
    pageNo: number,
    size: number,
    sortBy = '',
    sortDirection = '',
    type = '',
    parent = '',
    search = '',
    skipPagination = false,
    skipStateUpdate = false
  ): Observable<{ data: any[]; totalPages: number; totalItems: number }> {
    return this._httpClient
      .get<{ data: any[]; totalPages: number; totalItems: number }>(environment.apiURL + 'cityStatesCountry/fetchCSC', {
        params: {
          pageNo,
          size,
          sortBy,
          sortDirection,
          type,
          parent,
          search,
          skipPagination,
        },
      })
      .pipe(
        tap((response) => {
          if (!skipStateUpdate) {
            this._newCountryCityStates.next(response);
          }
        })
      );
  }

  public syncCSC(countryCode: string) {
    return this._httpClient.post<{ message: string }>(environment.apiURL + 'cityStatesCountry/sync', {
      countryCode,
    });
  }
}
