import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { FoodContentInfo } from '../models';

@Injectable({
  providedIn: 'root',
})
export class FoodContentInfoService {
  // Private
  private _foodContentInfos: BehaviorSubject<FoodContentInfo[] | null> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  get foodContentInfos$(): Observable<FoodContentInfo[]> {
    return this._foodContentInfos.asObservable();
  }

  getAllFoodContentInfo(): Observable<FoodContentInfo[]> {
    return this._httpClient.get<FoodContentInfo[]>(environment.apiURL + 'foodcontentinfo/adminAll').pipe(
      tap((foodContentInfos) => {
        this._foodContentInfos.next(foodContentInfos);
      })
    );
  }

  getAllFoodContentInfoApp(): Observable<FoodContentInfo[]> {
    return this._httpClient
      .get<{ foodContentInfo: FoodContentInfo[]; message: string }>(environment.apiURL + 'foodcontentinfo/all')
      .pipe(map((foodContentInfos) => foodContentInfos.foodContentInfo));
  }

  create(data: any): Observable<{ foodContentInfo: FoodContentInfo; message: string }> {
    return this.foodContentInfos$.pipe(
      take(1),
      switchMap((allrecords) =>
        this._httpClient
          .post<{ foodContentInfo: FoodContentInfo; message: string }>(environment.apiURL + 'foodcontentinfo/add', data)
          .pipe(
            map((newFoodContentInfo) => {
              // Update the products with the new product
              this._foodContentInfos.next([newFoodContentInfo.foodContentInfo, ...allrecords]);

              // Return the new product
              return newFoodContentInfo;
            })
          )
      )
    );
  }

  update(data: any, id: string): Observable<{ foodContentInfo: FoodContentInfo; message: string }> {
    return this.foodContentInfos$.pipe(
      take(1),
      switchMap((allrecords) =>
        this._httpClient
          .put<{ foodContentInfo: FoodContentInfo; message: string }>(
            environment.apiURL + 'foodcontentinfo/edit/' + id,
            data
          )
          .pipe(
            map((updateData) => {
              const index = allrecords.findIndex((item) => item._id === id);

              allrecords[index] = updateData.foodContentInfo;

              this._foodContentInfos.next(allrecords);

              return updateData;
            })
          )
      )
    );
  }

  enableDisable(id: string): Observable<{ isDisabled: boolean; message: string }> {
    return this.foodContentInfos$.pipe(
      take(1),
      switchMap((allrecords) =>
        this._httpClient
          .get<{ isDisabled: boolean; message: string }>(environment.apiURL + 'foodcontentinfo/enableDisable/' + id)
          .pipe(
            map((updateData) => {
              const index = allrecords.findIndex((item) => item._id === id);

              allrecords[index].isDisabled = updateData.isDisabled;

              this._foodContentInfos.next(allrecords);

              return updateData;
            })
          )
      )
    );
  }

  public delete(id: string) {
    return this.foodContentInfos$.pipe(
      take(1),
      switchMap((allrecords) =>
        this._httpClient.delete<any>(environment.apiURL + 'foodcontentinfo/delete/' + id).pipe(
          map((response) => {
            // Find the index of the deleted item
            const index = allrecords.findIndex((item) => item._id === id);

            // Delete the item
            allrecords.splice(index, 1);

            // Update the items
            this._foodContentInfos.next(allrecords);
            return response;
          })
        )
      )
    );
  }
}
