<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
  <span class="relative">
    <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    <span
      class="absolute right-0 bottom-0 w-2 h-2 rounded-full"
      [ngClass]="{ 'mr-px mb-px': true, 'bg-green-500': true }"
    ></span>
  </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
  <button mat-menu-item>
    <span class="flex flex-col leading-none">
      <span>Signed in as</span>
      <span class="mt-1.5 text-md font-medium">{{ user.email }}</span>
    </span>
  </button>
  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item (click)="signOut()">
    <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
    <span>Sign out</span>
  </button>
</mat-menu>
