<!-- PROGRESS BAR -->
<fuse-progress-bar></fuse-progress-bar>
<!-- / PROGRESS BAR -->

<!-- Navigation -->
<fuse-vertical-navigation
  class="dark bg-gray-900 print:hidden"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="data.navigation.default"
  [opened]="!isScreenSmall"
>
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <!-- Logo -->
    <div class="flex items-center h-36 p-6 pb-0">
      <!-- Light version -->
      <img class="dark:hidden" src="assets/images/logo/logo-final.svg" alt="Logo image" />
      <!-- Dark version -->
      <img class="hidden dark:flex" src="assets/images/logo/logo-final.svg" alt="Logo image" />
    </div>
  </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <div
    class="
      relative
      flex flex-0
      items-center
      w-full
      h-16
      px-4
      md:px-6
      z-49
      shadow
      dark:shadow-none dark:border-b
      bg-card
      dark:bg-transparent
      print:hidden
    "
  >
    <!-- Navigation toggle button -->
    <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
      <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
    </button>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-2">
      <fuse-fullscreen></fuse-fullscreen>
      <!-- <search [appearance]="'bar'"></search> -->
      <!-- <shortcuts [shortcuts]="data.shortcuts"></shortcuts> -->
      <!-- <messages [messages]="data.messages"></messages>
            <notifications [notifications]="data.notifications"></notifications> -->
      <user-menu></user-menu>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer -->
  <!-- <div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Foodaholix &copy; {{currentYear}}</span>
    </div> -->
</div>
