import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { AutomaticNotification } from '../models';

interface IPaginateResponse {
  data: AutomaticNotification[];
  totalPages: number;
  totalItems: number;
}

@Injectable({
  providedIn: 'root',
})
export class AutomaticNotificationService {
  private _allNotifications: BehaviorSubject<IPaginateResponse | null> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get allNotifications$(): Observable<IPaginateResponse> {
    return this._allNotifications.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getAll(
    pageNo: number,
    size: number,
    sortBy?: string,
    sortDirection?: string,
    search = ''
  ): Observable<IPaginateResponse> {
    return this._httpClient
      .get<IPaginateResponse>(environment.apiURL + 'an/all', {
        params: {
          pageNo,
          size,
          sortBy,
          sortDirection,
          search,
        },
      })
      .pipe(
        tap((response) => {
          this._allNotifications.next(response);
        })
      );
  }

  delete(id: string) {
    return this.allNotifications$.pipe(
      take(1),
      switchMap((records) =>
        this._httpClient.delete<{ message: string }>(environment.apiURL + 'an/del/' + id).pipe(
          map((updateData: any) => {
            // Find the index of the updated record
            const index = records.data.findIndex((item) => item._id === id);

            records.data.splice(index, 1);

            // Update the state
            this._allNotifications.next(records);

            return updateData;
          })
        )
      )
    );
  }

  public uploadnotifications(data: any) {
    return this._httpClient.post<any>(environment.apiURL + 'an/bulkupload', data);
  }

  create(data: any): Observable<{ message: string; data: AutomaticNotification }> {
    return this.allNotifications$.pipe(
      take(1),
      switchMap((records) =>
        this._httpClient
          .post<{ message: string; data: AutomaticNotification }>(environment.apiURL + 'an/add', data)
          .pipe(
            map((d) => {
              records.data.unshift(d.data);

              this._allNotifications.next(records);

              return d;
            })
          )
      )
    );
  }

  update(id: string, data: AutomaticNotification): Observable<{ message: string; data: AutomaticNotification }> {
    return this.allNotifications$.pipe(
      take(1),
      switchMap((records) =>
        this._httpClient
          .put<{ message: string; data: AutomaticNotification }>(environment.apiURL + 'an/edit/' + id, data)
          .pipe(
            map((updateData) => {
              const index = records.data.findIndex((item) => item._id === id);

              records.data[index] = updateData.data;

              this._allNotifications.next(records);

              return updateData;
            })
          )
      )
    );
  }
}
